.CategoryCard-category-name {
  font-size: 1.3rem;
  color: #453e59;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 30px;
  vertical-align: text-bottom;
}

.CategoryCard-edit-icon{
  vertical-align: bottom;
  cursor: pointer;
}