.DealSingle-middle{
  display: flex;
  justify-content: space-between;
}

.DealSingle-unactive{
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #FFE2E5;
  color:#F75E6F;
}

.DealSingle-progress{
  width:40%;
}

.DealSingle-bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:15px;
  padding-top:7px;
  border-top:1px solid #E7E9F5;
}

.DealSingle-live{
  background-color: #0DBB87;
    color: white;
    padding: 8px 16px;
    border-radius: 7px;
}

.fontw-600{
  font-weight: 600;
}

.DS-tc{
  color:#646C9B
}

.DealSingle-title{
  color:#4A4A4A
}