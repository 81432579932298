//
// Header
//

// Desktop Mode
@include media-breakpoint-up(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    height: get($header-config, desktop, default, height);
    position: relative;
    z-index: 2;

    // Fixed Header Mode
    &.header-fixed {
      height: get($header-config, desktop, fixed, height);
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, desktop, fixed, zindex);
    }

    // Aside Enabled Mode
    .aside-enabled & {
      &.header-fixed {
        left: get($aside-config, base, width);
      }
    }

    .aside-not-shown & {
      &.header-fixed {
        left: 0 !important;
      }
    }

    // Fixed Header & Minimized Aside & Minimized Aside Hover Modes
    .header-fixed.aside-minimize-hover &,
    .header-fixed.aside-minimize & {
      left: get($aside-config, base, minimized-width);
    }

    // Fixed Header & Fixed Subheader Modes
    .header-fixed.subheader-fixed & {
      box-shadow: none !important;
    }
  }
}
