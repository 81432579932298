.TableTopBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:10px 20px 10px 30px;
  border-bottom: 1px solid #E7E9F5;
}

.TableTopBar-heading{
  margin:0;
  font-size: 1.3rem;
}

.TableTopBar-add-icon{
  color:white;
}

.TableTopBar-submit{
  background-color: #657EFF !important;
}