.TopServices-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.TopServices-list{
  list-style-type: none;
  padding:0;
  margin:5px 0 0 0;
}

.TopServices-list-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TopServices-list-item p{
  font-weight:600;
}