.StylistListing{
  height: 100%;
}

.StylistsTable{
  min-width: 870px;
}

.StylistsTable tbody tr td{
  vertical-align: middle;
}
.StylistListingStatus-width{
  min-width:70px;
}

.StylistListing-top-container{
  height: 100%;
}

.StylistListing-table-container{
  padding: 10px 15px;
}

.StylistListingCategory-width{
  width: 120px;
}

.StylistListingName-width{
  width: 170px;
}