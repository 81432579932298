.RanksListing{
  height: 100%;
}

.RanksListing-top-container{
  height: 100%;
}

.RanksListing-table-container{
  padding: 10px 15px;
}

.RanksListing-table{
  min-width: 400px;
}

@media(min-width:600px){
  .RanksListing-table{
    width: 80% !important;
    margin: 0 auto;
  }
  .RanksListing-width-50{
    width: 50%;
  }
  
  .RanksListing-width-20{
    width: 20%;
  }
}

.RanksListing-rows{
  line-height: 3rem;
}

.RanksListing-rows td{
  border-top: none !important;
}

