.CategoryCard {
  background-color: white;
  margin-bottom: 1.5rem;
  border-radius:12px;
}

.CategoryCard-category-name {
  font-size: 1.3rem;
  color: #453e59;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 30px;
  vertical-align: text-bottom;
}

.CategoryCard-collapse{
  border-top: 1px solid #e7e9f5;
}


.CategoryCard-service-name{
  font-size: 1.3rem;
  color: #453e59;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 30px;
  vertical-align: text-bottom;

}

.CategoryCard-service-variation{
  border-top: 1px dashed #e7e9f5;
}

.CategoryCard-service-variation-name{
  padding-left: 50px;
}