/***
@author kishan
***/
#service-page{

  #add-new{
    float: right;
    // height: 100px;
  }
  
  #service-list-container{
    margin-top: 30px;
    // padding-left: 94px;
    // padding-right: 168px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-container{
    background-color: #FFFFFF;
    border:1px solid #E7E9F5;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #E7E9F5;
    margin-bottom: 20px;

    svg{
      cursor: pointer;
    }
  }
  #menu{
    height: 26px;
    width: 26px;
    fill: #ABABAB;
    margin-right: 40px;
  }
  #more{
    width: 22px;
    height: 15px;
  }
  #file-plus{
    height: 40px;
    width: 33px;
    color:#0DBB87
  }

  .left-section{
    padding: 12px 24px;

    line-height: 40px;
    svg{
      display: inline;
    }
    div{
      color: #453E59;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      display: inline;
    }
  }
  .right-section{
    padding: 12px 24px;

    #file-plus{
      margin-right: 18px;
    }
  }
  .right-section > svg{
    display: inline;
  }
  .sub-category{
    clear: both;
    border-top: 1px solid #E7E9F5;
    #gel{
      height: 26px;
      width: 26px;
      fill: #0DBB87;
    }
    .sub-category-name{
      color: #696969;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      font-weight: normal;
    }
    @media (min-width:701px){
      .middle-section{
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100px;
        margin: auto;
        height: 16px;
      }
    }
    .middle-section{
    color: #696969;
    font-family: Poppins;
    font-size: 14px;
    }
  }
  .price{
    color: #696969;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: right;
  }
  .sub-category-list {
    border-top: 1px dashed #E7E9F5;
    .sub-category-name{
      margin-left: 37px;
    }
    .left-section{
      padding-left: 89px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

@media (min-width:1100px){
  #service-page   #service-list-container{
    padding-left: 94px !important;
    padding-right: 168px !important;
  }
}

@media(max-width:700px){
  #service-page #service-list-container{
    padding: 0px !important;
  }
  #service-page{
    padding: 10px !important;
  }
  #service-page .left-section, #service-page .right-section{
    padding: 12px 10px !important;
  }
  #service-page .middle-section{
    position: absolute;
    bottom: 16px;
    left: 56px;
  }
  #service-page .sub-category .left-section{
    padding-left: 10px !important;
  }
  #service-page .sub-category > div > .left-section{
    padding-bottom: 30px !important;
  }
  #service-page .sub-category-list .sub-category-name{
    margin-left: 20px !important;
  }
  #service-page #menu{
    margin-right: 20px !important;
  }
  #service-page .sub-category .middle-section{
    position: absolute;
    bottom: 20px;
  }
}

/***
 new service page
***/
 #new-service-page{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #F8F9FF;
  z-index: 100;
  
  .close-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:20px;
    right: 20px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    svg{
      height: 10px;
      width:10px;
    }
  }
  .nav-item{
    border: 0px;
    margin-bottom: 0px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    color:#646C9B;
    padding-top: 0px;
    padding-bottom: 0px;
    span{
      padding-top: 9.75px;
      padding-bottom: 9.75px;
    }
    svg{
      height: 14px;
      fill:#646C9B;
      &:hover{
        fill:#657EFF !important
      }
    }
    &.active{
      color:#657EFF !important;
      svg{
        fill:#657EFF
      }
      span{
        border-bottom: 2px solid;
      }
    }
    &:hover{
      color:#657EFF !important;
    }
  }
  
  #new-service-header{
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #91A0DD80;
    text-align: center;
    .navbar-text{
      width: 100%; 
    }
    span:nth-child(2){
      height: 25px;
      background: #E7E9F5;
      width: 25px;
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
    img{
      height: 11px;
      width: 11px;
      vertical-align: bottom;
    }
  } 
  #new-service-form-container{
    padding-top: 95px;
    padding-left: 57px;
    background-color: #F8F9FF;
  }
  #new-service-form{
    margin-top: 20px;
    .form-control{
    border: 1px solid #E7E9F5;
    border-radius: 4px;
    background-color: #FFFFFF;
    }
    .text-muted{
      color: #696969 !important;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px;
    }
    .dropdown{
      z-index: 10000;
    }
    #feature-container{
      margin-top: 10px;
      background-color: #FFFFFF;
      padding:20px;
      margin-bottom: 100px;
      padding-left: 30px;
      .feature-list-item{
        margin-bottom: 10px;
      }
      #add-more{
        margin-top: 24px;
        background-color: #DFF5EC;
        color: #0DBB87;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        border: 0px;
        width:115px;
      }
      .fa-trash{
        position: absolute;
        right: -16px;
        top: 36px;
        font-size: 16px;
        cursor: pointer;
      }
      .section-title{
        color: #646C9B;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 15px;
      }
      .card-title{
        color: #646C9B;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
      }
      .form-group{
        margin-bottom: 0px;
      }
      .rank-label{
        color: #696969;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin-right: 58px;
      }
      
    }
    #ranks-container{
      margin-top: 10px;
      background-color: #FFFFFF;
      padding:20px;
      margin-bottom: 100px;
      padding-left: 30px;
      .section-title{
        color: #646C9B;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 15px;
      }
      .card-title{
        color: #646C9B;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
      }
      .form-group{
        margin-bottom: 0px;
      }
      .rank-label{
        color: #696969;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin-right: 58px;
      }
    }
  }
  #pricing-details{
    margin-top: 30px;
  }
  #pricing-details-form{
    margin-top: 22px;
    .form-control,.input-group-prepend{
    height: 35px;
    }
    .fa{
      font-weight: normal;
      font-size: 14px;
      color: #646C9B;
    }
  } 
  #footer{
    height: 86.3px;
    border-top: 1px solid #9797974D;
    background-color: #FFFFFF;
    .action-buttons{
      margin-right: 109px;
      padding-top: 23px;
      button{
        width: 156px;
        border-radius: 4px;
        border: 0px;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        height: 43px;
        &:first-child{
          margin-right: 50px;
          color: #696969;
          background-color: #E7E9F5 !important;
        }
        &:last-child{
          color: #FFFFFF;
          background-color: #9B9B9B !important;

        }
      }
    }
  }
  .right-side{
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
    height: 100vh;
    padding-left: 0px;
    right: 0;
    position: fixed;
    padding-top: 56px;
    padding-right: 0px;
  }
  #right-side{
    
    .stylist-card{
      margin-bottom: 20px;
    }
  }
  .tab-container{
    padding: 30px;
    .note{
      color: #646C9B;
      font-family: Poppins;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }
}

@media(max-width:765px){
  #new-service-page .right-side{
    position: relative;
  }
  #new-service-form-container{
    padding-left: 40px !important;
    padding-right:40px;
    padding-bottom: 40px;
  }
  #new-service-page #new-service-form #ranks-container{
    margin-bottom: 0px;
  }
  #new-service-page .right-side{
    padding-top: 10px !important;
    height: auto;
    margin-bottom: 80px;
  }
  #new-service-page .action-buttons{
    text-align: center;
    margin-right: 0px !important;
    float: none !important;
  }
}
