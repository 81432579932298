.wizard.wizard-4 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wizard.wizard-4 .wizard-nav .wizard-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(25% - 0.25rem);
  flex: 0 0 calc(25% - 0.25rem);
  width: calc(25% - 0.25rem);
  background-color: #f3f6f9;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #464e5f;
  padding: 2rem 2.5rem;
}
.wizard.wizard-4
  .wizard-nav
  .wizard-steps
  .wizard-step
  .wizard-wrapper
  .wizard-number {
  font-size: 1.3rem;
  font-weight: 600;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.75rem;
  flex: 0 0 2.75rem;
  height: 2.75rem;
  width: 2.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(54, 153, 255, 0.08);
  color: #3699ff;
  margin-right: 1rem;
  border-radius: 0.5rem;
}
.wizard.wizard-4
  .wizard-nav
  .wizard-steps
  .wizard-step
  .wizard-wrapper
  .wizard-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wizard.wizard-4
  .wizard-nav
  .wizard-steps
  .wizard-step
  .wizard-wrapper
  .wizard-label
  .wizard-title {
  font-size: 1.1rem;
  font-weight: 600;
}
.wizard.wizard-4
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"] {
  background-color: #ffffff;
}
.wizard.wizard-4
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-wrapper
  .wizard-number {
  color: #ffffff;
  background-color: #3699ff;
}
.wizard.wizard-4
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]
  .wizard-wrapper
  .wizard-label
  .wizard-title {
  color: #3699ff;
}

@media (max-width: 1399.98px) {
  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 0.25rem);
    flex: 0 0 calc(50% - 0.25rem);
    width: calc(50% - 0.25rem);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 767.98px) {
  .wizard.wizard-4 .wizard-nav .wizard-steps {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    position: relative;
    width: 100%;
  }
  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 0.5rem 2rem;
  }
}

.drag-drop-container {
  height: 180px;
  width: 339px;
  border: 2px dashed #e7e9f5;
  border-radius: 5px;
}
