.amount-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 97px;
  width: 100%;
  border: 1px solid #e7e9f5;
  border-radius: 8px;
  background-color: rgba(231, 233, 245, 0.35);
  box-shadow: 0 2px 4px 0 #e7e9f5;
  font-size: 26px;
  color: #646c9b;
}
.amount-label {
  font-size: 16px;
  color: #646c9b;
}
.amount-text {
  font-size: 26px;
  color: #646c9b;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
}
.cardText {
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.paymentCTAcontainer {
  width: 95%;
  flex-wrap: wrap;
}
.paymentCTA {
  height: 97px;
  width: 47.78%;
  border-radius: 8px;
  background-color: #43434a;
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  border: 1px solid #e7e9f5;
  box-shadow: 0 2px 4px 0 #e7e9f5;
  margin-bottom: 5px;
}
.paymentCTA:nth-child(odd) {
  margin-right: 15px;
}
.button {
  height: 43px;
  width: 193px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
  letter-spacing: 0;
  line-height: 1.5;
}
.button-more_options {
  width: 45%;
}
.button-confirm_sale {
  width: 45%;
}
.button-container {
  position: absolute;
  bottom: 0px;
}
