.p-component, .p-component * {
    box-sizing: border-box;
}
.p-hidden {
    display: none;
}
.p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.p-hidden-accessible input,
.p-hidden-accessible select {
    transform: scale(0);
}
.p-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}
.p-disabled {
    cursor: default !important;
}
.p-disabled a {
    cursor: default !important;
}
.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.p-overflow-hidden {
    overflow: hidden;
}
.p-unselectable-text {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.p-scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}
.p-clearfix:after {
    content: "";
    display: table;
    clear: both;
}

@-webkit-keyframes p-fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes p-fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

/* Overlay Animations */
.p-input-overlay {
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    opacity: 0;
    -webkit-transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s;
}

.p-input-overlay-visible {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.p-input-overlay-hidden {
    opacity: 0;
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    -webkit-transition: transform .3s, opacity .15s;
    transition: transform .3s, opacity .15s;
}

.p-menu-overlay {
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    opacity: 0;
    -webkit-transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s;
}

.p-menu-overlay-visible {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.p-menu-overlay-hidden {
    opacity: 0;
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    -webkit-transition: transform .3s, opacity .15s;
    transition: transform .3s, opacity .15s;
}

.p-link {
    text-align: left;
    background-color: transparent;
    background: transparent;
	margin: 0;
	padding: 0;
	border: none;
	cursor: pointer;
}

/* Toggleable content with slide animation */
.p-toggleable-content-collapsed {
    display: none;
}

.p-toggleable-content-enter {
    max-height: 0;
    display: block;
}

.p-toggleable-content-enter-active {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height cubic-bezier(0.86, 0, 0.07, 1) 400ms;
}

.p-toggleable-content-enter-done {
    display: block;
}

.p-toggleable-content-exit {
    max-height: 1000px;
    display: block;
}

.p-toggleable-content-exit-active {
    max-height: 0;
    display: block;
    overflow: hidden;
    transition: max-height cubic-bezier(0.86, 0, 0.07, 1) 250ms;
}

.p-toggleable-content-exit-done {
    display: none;
}

/* Floating Label */
.p-float-label {
    display: block;
    position:relative;
}

.p-float-label > label {
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left: .25em;
  top: 50%;
  margin-top: -.5em;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  color: #898989;
  line-height: 1;
}

.p-accordion { 
    width: 100%; 
}

.p-accordion .p-accordion-header { 
    cursor: pointer; 
    position: relative; 
    margin-top: 1px; 
    zoom: 1; 
}

.p-accordion .p-accordion-header a { 
    display: block; 
    padding: .5em;
}

.p-accordion .p-accordion-toggle-icon,
.p-accordion .p-accordion-header-text {
    vertical-align: middle;   
}

.p-accordion .p-accordion-header a > span { 
    display: inline-block; 
    vertical-align: middle;
}

.p-accordion .p-accordion-content { 
    padding: 1em;
    border-top: 0;
    zoom: 1;
}

.p-accordion .p-accordion-header.p-disabled, 
.p-accordion .p-accordion-header.p-disabled a { 
    cursor: default; 
}

.p-accordion .p-accordion-content-wrapper-collapsed {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.p-accordion .p-accordion-content-wrapper-expanded {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
}

.p-accordion .p-accordion-content-wrapper-expanding {
    overflow: hidden;
}
.p-autocomplete {
    width: auto;
    zoom: 1;
    cursor: pointer;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    display: inline-block;
}

.p-autocomplete .p-autocomplete-dropdown {
    height: 100%;
    width: 2em;
    margin-right: 0;
    vertical-align: top;
}

.p-autocomplete .p-autocomplete-input {
    padding-right: 1.5em;
}

.p-autocomplete-loader {
    position: absolute;
    right: .25em;
    top: 50%;
    margin-top: -.5em;
}

.p-autocomplete-query {
    font-weight: bold;
}

.p-autocomplete .p-autocomplete-panel {
    min-width: 100%;
}

.p-autocomplete-panel {
    position: absolute;
    overflow: auto;
    display: none;
}

.p-autocomplete-panel .p-autocomplete-list {
    padding: 0.4em;
    border: 0 none;
}

.p-autocomplete-panel .p-autocomplete-list-item {
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin: 1px 0;
    padding: 0.186em 0.313em;
    text-align: left;
}

.p-autocomplete .p-button-icon-only,
.p-autocomplete .p-button-icon-only:enabled:hover,
.p-autocomplete .p-button-icon-only:enabled:focus,
.p-autocomplete .p-button-icon-only:enabled:active {
    border-left: 0 none;
}

/* Multiple Selection */
.p-autocomplete-multiple-container {
    display: inline-block;
    vertical-align: middle;
}

.p-autocomplete-multiple-container.p-inputtext {
    clear: left;
    cursor: text;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 1.5em 0 .25em;
}

.p-autocomplete-token {
    cursor: default;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    padding: .125em .5em;
    white-space: nowrap;
    position: relative;
    margin-right: .125em;
    border: 0 none;
    font-size: .9em;
}

.p-autocomplete-token-label {
    display: block;
    margin-right: 2em;
}

.p-autocomplete-token-icon {
    margin-top: -.5em;
    position: absolute;
    right: 0.2em;
    top: 50%;
    cursor: pointer;
}

.p-autocomplete-input-token {
    display: inline-block;
    vertical-align: middle;
    list-style-type: none;
    margin: 0 0 0 .125em;
    padding: .25em .25em .25em 0;
}

.p-autocomplete-input-token input {
    border: 0 none;
    width: 10em;
    outline: medium none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.25em;
}

.p-autocomplete-dd input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
     -moz-border-radius-topright: 0px; 
     -webkit-border-top-right-radius: 0px;
     border-top-right-radius: 0px;
     -moz-border-radius-bottomright: 0px;
     -webkit-border-bottom-right-radius: 0px;
     border-bottom-right-radius: 0px;
 }
 
.p-autocomplete-dd .p-autocomplete-dropdown {
     -moz-border-radius-topleft: 0px; 
     -webkit-border-top-left-radius: 0px;
     border-top-left-radius: 0px;
     -moz-border-radius-bottomleft: 0px;
     -webkit-border-bottom-left-radius: 0px;
     border-bottom-left-radius: 0px;
}

/** AutoComplete **/
.p-fluid .p-autocomplete,
.p-fluid .p-autocomplete-input {
    width: 100%;
}

.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-input,
.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-multiple-container {
    width: calc(100% - 2em);
}

.p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}
/** Breadcrumb **/
.p-breadcrumb {
    margin: 0;
    padding: 0;
    padding: .5em;
}

.p-breadcrumb ul {
    margin: 0;
    padding: 0;
}

.p-breadcrumb ul li {
    display: inline-block;
    margin: 0 .25em;
}

.p-breadcrumb-chevron, .p-breadcrumb-home {
    vertical-align: middle;
}

.p-breadcrumb ul li .p-menuitem-link {
    text-decoration: none;
}

.p-breadcrumb .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-breadcrumb .p-menuitem-text {
    vertical-align: middle;
}
/* Button */
.p-button {
    display: inline-block;
    position: relative;
    padding: 0;
    text-decoration: none !important;
    cursor: pointer;
    text-align: center;
    zoom: 1;
    overflow: visible; /* the overflow property removes extra width in IE */
    margin-right: 0.25em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

/*button text element */
.p-button .p-button-text {
    display: block;
    line-height: normal;
}

.p-button-text-only .p-button-text {
    padding: .25em 1em;
}

.p-button-icon-only .p-button-text,
.p-button-text-empty .p-button-text {
    padding: .25em;
    text-indent: -9999999px;
}

.p-button-text-icon-left .p-button-text {
    padding: .25em 1em .25em 2.1em;
}

.p-button-text-icon-right .p-button-text {
    padding: .25em 2.1em .25em 1em;
}

/*button icon element(s) */
.p-button-icon-only .p-button-icon-left,
.p-button-text-icon-left .p-button-icon-left,
.p-button-text-icon-right .p-button-icon-right {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    height: 1em;
}

.p-button-icon-only .p-button-icon-left {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    width: 1em;
    height: 1em;
}

.p-button-icon-left {
    left: .5em;
}

.p-button-icon-right {
    right: .5em;
}

/*button sets*/
.p-buttonset .p-button {
    margin-left: 0;
    margin-right: 0;
}

/* workarounds */
button.p-button::-moz-focus-inner {
    border: 0; padding: 0; /* reset extra padding in Firefox */
}

/** Fluid **/
.p-fluid .p-button {
    width: 100%;
}

.p-fluid .p-button-text-icon-left .p-button-text,
.p-fluid .p-button-text-icon-right .p-button-text {
    padding-left: 1em;
    padding-right: 1em;
}

/** ButtonSet **/
.p-fluid .p-buttonset {
    width: 100%;
}

.p-fluid .p-buttonset.p-buttonset-1 .p-button {width: 100%;}
.p-fluid .p-buttonset.p-buttonset-2 .p-button {width: 50%;}
.p-fluid .p-buttonset.p-buttonset-3 .p-button {width: 33.3%;}
.p-fluid .p-buttonset.p-buttonset-4 .p-button {width: 25%;}
.p-fluid .p-buttonset.p-buttonset-5 .p-button {width: 20%;}
.p-fluid .p-buttonset.p-buttonset-6 .p-button {width: 16.6%;}

@media (max-width: 640px) {
    .p-fluid .p-buttonset.p-buttonset-1 .p-button,
    .p-fluid .p-buttonset.p-buttonset-2 .p-button,
    .p-fluid .p-buttonset.p-buttonset-3 .p-button,
    .p-fluid .p-buttonset.p-buttonset-4 .p-button,
    .p-fluid .p-buttonset.p-buttonset-5 .p-button,
    .p-fluid .p-buttonset.p-buttonset-6 .p-button {
        width: 100%;
    }
}
.p-calendar {
    position: relative;
    display: inline-block;
}

.p-calendar .p-calendar-button {
    position: absolute;
    height: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 2em;
    border-left: 0 none;
}

.p-calendar .p-calendar-button:enabled:hover,
.p-calendar .p-calendar-button:focus {
    border-left: 0 none;
}

.p-calendar .p-datepicker {
    min-width: 100%;
}

/* Fluid */
.p-fluid .p-calendar {
    width: 100%;
}

.p-fluid .p-calendar-button {
    width: 2em;
}

.p-fluid .p-datepicker-buttonbar button {
    width: auto;
}

.p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
    width: calc(100% - 2em);
}

/* Datepicker */
.p-datepicker {
	width: auto;
	padding: .2em;
	display: none;
    position: absolute;
}

.p-datepicker.p-datepicker-inline {
    display: inline-block;
    position: static;
}

.p-datepicker .p-datepicker-group {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
}

/* Header */
.p-datepicker .p-datepicker-header {
	position: relative;
	padding: .5em 0;
}
.p-datepicker .p-datepicker-prev,
.p-datepicker .p-datepicker-next {
	position: absolute;
	top: .5em;
	width: 1.8em;
    height: 1.8em;
    cursor: pointer;
}

.p-datepicker .p-datepicker-prev {
	left: .125em;
}
.p-datepicker .p-datepicker-next {
	right: .125em;
}

.p-datepicker .p-datepicker-prev span,
.p-datepicker .p-datepicker-next span {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
}

.p-datepicker .p-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
}
.p-datepicker .p-datepicker-title select {
	font-size: 1em;
	margin: .125em 0;
    vertical-align: middle;
}
.p-datepicker select.p-datepicker-month {
    margin-right: .25em;
}
.p-datepicker span.p-datepicker-year {
	margin-left: .25em;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group {
    display: table-cell;
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-right-width: 1px;
}

/* DatePicker Table */
.p-datepicker table {
	width: 100%;
	font-size: .9em;
	border-collapse: collapse;
	margin: 0 0 .4em;
}
.p-datepicker th {
	padding: .5em;
	text-align: center;
	font-weight: bold;
	border: 0;
}
.p-datepicker td {
	border: 0;
	padding: 0;
}
.p-datepicker td > span,
.p-datepicker td > a {
	display: block;
	padding: .5em;
	text-align: right;
	text-decoration: none;
}

.p-datepicker td a {
    cursor: pointer;
}

.p-datepicker .p-datepicker-buttonbar,
.p-datepicker .p-datepicker-footer {
	padding: .5em;
}

.p-datepicker .p-datepicker-buttonbar:after {
    content: "";
    display: table;
    clear: both;
}

.p-datepicker .p-datepicker-buttonbar > button:last-child {
    float: right;
}

.p-calendar.p-calendar-w-btn input {
    -moz-border-radius-topright: 0px;
    -webkit-border-top-right-radius: 0px;
    -khtml-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -khtml-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* Month Picker */
.p-monthpicker .p-monthpicker-month {
    width: 33.3%;
    display: inline-block;
    text-align: center;
    padding: .5em;
    cursor: pointer;
}

.p-datepicker-monthpicker select.p-datepicker-year {
    width: auto;
}

/* Time Picker */
.p-timepicker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: .5em 0;
}

.p-timepicker > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: .5em;
    min-width: 1.5em;
}

.p-timepicker > .p-minute-picker,
.p-timepicker > .p-second-picker,
.p-timepicker > .p-millisecond-picker {
    margin-left: 0;
}

.p-timepicker > .p-separator {
    margin-left: 0px;
    min-width: .75em;
}

.p-timepicker > .p-separator .p-separator-spacer {
    visibility: hidden;
    display: block;
}

.p-timepicker > .p-separator .p-separator-spacer:last-child {
    margin-top: .3em;
}

.p-timepicker > div button {
    display: block;
    cursor: pointer;
}

.p-timepicker > div button:last-child {
    margin-top: .3em;
}

input[type=text]::-ms-clear {
    display: none;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 80vw;
    transform: translate(-50%, -50%);
}

.p-datepicker-touch-ui.p-datepicker th {
	padding: 2em 0;
}
.p-datepicker-touch-ui.p-datepicker td {
	padding: 0;
}
.p-datepicker-touch-ui.p-datepicker td span,
.p-datepicker-touch-ui.p-datepicker td a {
	padding: 2em 0;
}

.p-datepicker-touch-ui .p-timepicker {
    padding: 1em 0;
}

.p-datepicker-touch-ui .p-timepicker > div a {
    font-size: 2em;
}

.p-datepicker-mask {
    position: fixed;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 40em) {
    .p-datepicker-multiple-month {
        width: 17em;
        overflow: auto;
    }

    .p-datepicker-touch-ui.p-datepicker th {
        padding: 1em 0;
    }

    .p-datepicker-touch-ui.p-datepicker td span,
    .p-datepicker-touch-ui.p-datepicker td a {
        padding: 1em 0;
    }
}

.p-card-header img {
    width: 100%;
}

.p-card-body {
    padding: 1em;
}

.p-card-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: .5em;
}

.p-card-subtitle {
    opacity: .7;
    margin-bottom: .5em;
    margin-top: -.25em;
    font-weight: bold;
}

.p-card-footer {
    padding-top: 1em;
}
.p-carousel {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.p-carousel-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0 .5em;
}

.p-carousel-prev,
.p-carousel-next {
    align-self: center;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 2.5em;
    height: 2.5em;
}

.p-carousel-prev span,
.p-carousel-next span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-carousel-container {
    display: flex;
    flex-direction: row;
    padding: 0 .1em;
}

.p-carousel-items-content {
    overflow: hidden;
    width: 100%;
}

.p-carousel-header,
.p-carousel-footer {
    padding: 0 .5em;
    z-index: 1;
}

.p-carousel-items-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.p-carousel-items-container .p-carousel-item {
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.p-carousel-dots-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: .5em;
}

/* Vertical */
.p-carousel-vertical .p-carousel-container {
    flex-direction: column;
    width: 100%;
}

.p-carousel-vertical .p-carousel-items-container {
    flex-direction: column;
    height: 100%;
}

.p-carousel-vertical .p-carousel-dots-container {
    margin: .75em 0;
}

/* Keyboard Support */
.p-items-hidden .p-carousel-item {
    visibility: hidden;
}

.p-items-hidden .p-carousel-item.p-carousel-item-active {
    visibility: visible;
}
.p-checkbox {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    margin-right: .25em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.p-checkbox .p-checkbox-box {
    width: 1.125em;
    height: 1.125em;
    line-height: 1.125em;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
}

.p-checkbox .p-checkbox-icon {
    display: block;
}

.p-checkbox-label {
    vertical-align: middle;  
}

.p-checkbox + label {
    vertical-align: middle;
}
.p-chart {
    position: relative
}
.p-chips {
    display: inline-block;
}

.p-chips > ul.p-inputtext {
    clear: left;
    cursor: text;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 .25em;
}

.p-chips-token {
    cursor: default;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    padding: .125em .5em;
    white-space: nowrap;
    position: relative;
    margin-right: .125em;
    border: 0 none;
    font-size: .9em;
}

.p-chips-token .p-chips-token-label {
    display: block;
    margin-right: 2em;
}

.p-chips > .p-disabled .p-chips-token-label {
    margin-right: 0;
}

.p-chips-token .p-chips-token-icon {
    margin-top: -.5em;
    position: absolute;
    right: 0.2em;
    top: 50%;
    cursor: pointer;
}

.p-chips-input-token {
    display: inline-block;
    vertical-align: middle;
    list-style-type: none;
    margin: 0 0 0 .125em;
    padding: .25em .25em .25em 0;
}

.p-chips-input-token .p-inputtext {
    border: 0 none;
    width: 10em;
    outline: medium none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.p-fluid .p-chips {
    display: block;
}
.p-colorpicker {
    display: inline-block;
} 

.p-colorpicker-dragging {
    cursor: pointer;
} 

.p-colorpicker-overlay {
    position: relative;
} 

.p-colorpicker-panel {
    position: relative;
    width: 193px;
    height: 166px;
    background-color: #323232;
    border-color: #191919;
}

.p-colorpicker-overlay-panel {
    display: none;
    position: absolute;
}

.p-colorpicker-preview {
    width: 2em;
    cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-content {
    position: relative;
}

.p-colorpicker-panel .p-colorpicker-color-selector {
    width: 150px;
    height: 150px;
    top: 8px;
    left: 8px;
    position: absolute;
}

.p-colorpicker-panel .p-colorpicker-color {
     width: 150px;
     height: 150px;
     background: transparent url("./images/color.png") no-repeat left top; 
}

.p-colorpicker-panel .p-colorpicker-color-handle {
     position: absolute;
     top: 0px;
     left: 150px;
     border-radius: 100%;
     width: 10px;
     height: 10px;
     border: 1px solid #ffffff;
     margin: -5px 0 0 -5px;
     cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-hue {
    background: transparent url("./images/hue.png") no-repeat left top; 
    width: 17px;
    height: 150px;
    top: 8px;
    left: 167px;
    position: absolute;
    opacity: .85;
}

.p-colorpicker-panel .p-colorpicker-hue-handle {
     position: absolute;
     top: 150px;
     left: 0px;
     width: 21px;
     margin-left: -2px;
     margin-top: -5px;
     height: 10px;
     border: 2px solid #ffffff;
     opacity: .85;
     cursor: pointer;
 }
 
 .p-colorpicker-panel.p-disabled .p-colorpicker-hue-handle,
 .p-colorpicker-panel.p-disabled .p-colorpicker-color-handle  {
      opacity: .5;
 }
.p-contextmenu {
    width: 12.5em;
    padding: .25em;
    position: absolute;
    display: none;
}

.p-contextmenu .p-menu-separator {
    border-width: 1px 0 0 0;
}

.p-contextmenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-contextmenu .p-submenu-list {
    display: none;
    position: absolute;
    width: 12.5em;
    padding: .25em;
    z-index: 1;
}

.p-contextmenu .p-menuitem-active > .p-submenu-list {
    display: block;
}

.p-contextmenu .p-menuitem-link {
    padding: .25em;
    display: block;
    position: relative;
}

.p-contextmenu .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-contextmenu .p-menuitem-text {
    vertical-align: middle;
}

.p-contextmenu .p-menuitem {
    position: relative;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -.5em;
    right: 0;
    top: 50%;
}

.p-contextmenu .p-menuitem-active > .p-submenu > .p-submenu-list {
    display: block !important;
}
.p-datascroller {
}

.p-datascroller .p-datascroller-header {
    text-align: center;
	padding: .5em .75em;
    border-bottom: 0 none;
}

.p-datascroller .p-datascroller-footer {
    text-align: center;
    padding: .25em .625em;
    border-top: 0px none;
}

.p-datascroller .p-datascroller-content {
    padding: .25em .625em;
}

.p-datascroller-inline .p-datascroller-content {
    overflow: auto;
}

.p-datascroller .p-datascroller-list {
    list-style-type: none; 
    margin: 0;
    padding: 0;
}
.p-dataview {
    position: relative;
}

.p-dataview .p-paginator {
    text-align: center;
}

.p-dataview-column {
    padding: .25em;
}

.p-dataview-content-empty {
    padding: .25em .625em;
}

.p-dataview .p-dataview-header,
.p-dataview .p-dataview-footer {
    text-align: center;
    padding: .5em .75em;
}

.p-dataview .p-dataview-header {
    border-bottom: 0 none;
}

.p-dataview .p-dataview-footer {
    border-top: 0 none;
}

.p-dataview .p-paginator-top {
    border-bottom: 0 none;
}

.p-dataview .p-paginator-bottom {
    border-top: 0 none;
}

.p-dataview.p-dataview-list > .p-dataview-content > div.p-grid > div {
    width: 100%;
}

/* Loader */
.p-dataview-loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.p-dataview-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2em;
}

.p-datatable {
    position: relative;
}

.p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tfoot > tr > td {
    padding: .25em .5em;
}

.p-datatable .p-sortable-column {
    cursor: pointer;
    user-select: none;
}

.p-datatable .p-sortable-column-icon {
    vertical-align: middle;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
    overflow-x: auto;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
    table-layout: auto;
}

/* Sections */
.p-datatable-header,
.p-datatable-footer {
    padding: .25em .5em;
    text-align: center;
    font-weight: bold;
}

.p-datatable-header {
    border-bottom: 0 none;
}

.p-datatable-footer {
    border-top: 0 none;
}

/* Paginator */
.p-datatable .p-paginator-top {
    border-bottom: 0 none;
}

.p-datatable .p-paginator-bottom {
    border-top: 0 none;
}

/* Scrollable */
.p-datatable-scrollable-wrapper {
    position: relative;
}
.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
    overflow: hidden;
    border: 0 none;
}

.p-datatable-scrollable-body {
    overflow: auto;
    position: relative;
}

.p-datatable-scrollable-body > table > .p-datatable-tbody > tr:first-child > td {
    border-top: 0 none;
}

.p-datatable-virtual-table {
    position: absolute;
}

/* Frozen Columns */
.p-datatable-frozen-view .p-datatable-scrollable-body {
    overflow: hidden;
}

.p-datatable-frozen-view > .p-datatable-scrollable-body > table > .p-datatable-tbody > tr > td:last-child {
    border-right: 0 none;
}

.p-datatable-unfrozen-view {
    position: absolute;
    top: 0px;
}

/* Filter */
.p-column-filter {
    width: 100%;
}

/* Resizable */
.p-datatable-resizable > .p-datatable-wrapper {
    overflow-x: auto;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
    overflow: hidden;
}

.p-datatable-resizable .p-resizable-column {
    background-clip: padding-box;
    position: relative;
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
    display: none;
}

.p-datatable .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: .5em;
    height: 100%;
    padding: 0px;
    cursor:col-resize;
    border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
}

/* Edit */
.p-datatable .p-datatable-tbody > tr > td.p-cell-editing .p-component {
    width: 100%;
}

/* Reorder */
.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
    position: absolute;
    display: none;
}

/* Toggleable RowGroup */
.p-datatable .p-rowgroup-header .p-row-toggler .p-row-toggler-icon {
    vertical-align: text-bottom;
}

/* Responsive */
.p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .p-datatable-responsive .p-datatable-thead > tr > th,
    .p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
		float: left;
		clear: left;
    }

    .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td .p-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    }
}

/* Loader */
.p-datatable-loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.p-datatable-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2em;
}

.p-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: none;
}

.p-component-overlay {
    pointer-events: auto;
}

.p-dialog-visible {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-dialog {
    display: flex;
    flex-direction: column;
    padding: 0;
    pointer-events: auto;
    max-height: 90%;
}
.p-dialog .p-dialog-titlebar {
    padding: .5em .75em;
    position: relative;
    border: 0;
    flex-shrink: 0;
}
.p-dialog .p-dialog-content {
    position: relative;
    border: 0;
    padding: .5em .75em;
    background: none;
    zoom: 1;
    overflow-y: auto;
}
.p-dialog-resizable .p-dialog-content {
    overflow: auto;
}
.p-dialog .p-resizable-handle {
    width: 14px;
    height: 14px;
    right: 3px;
    bottom: 3px;
    position: absolute;
    font-size: .1px;
    display: block;
    cursor: se-resize;
}
.p-draggable .p-dialog-titlebar {
    cursor: move;
}
.p-dialog .p-dialog-titlebar-icons {
    float: right;
}
.p-dialog .p-dialog-titlebar-icons:after {
    content: "";
    display: table;
    clear: both;
}
.p-dialog .p-dialog-titlebar-icon {
    text-decoration: none;
    padding: .125em;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid transparent;
}
.p-dialog .p-dialog-titlebar-icon span {
    display: block;
    margin: 0;
}
.p-dialog-footer {
    padding: 1em;
    text-align: right;
    flex-shrink: 0;
}
/* ConfirmDialog */
.p-confirmdialog {
    width: 30em;
}
.p-confirmdialog.p-dialog .p-dialog-content {
    padding: 1em 2em;
}
.p-confirmdialog .p-dialog-content .p-confirmdialog-icon {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: .5em;
}
.p-confirmdialog .p-dialog-content .p-confirmdialog-message {
    vertical-align: middle;
}
/* Fluid */
.p-fluid .p-dialog-footer .p-button {
    width: auto;
}
/* RTL */
.p-rtl .p-dialog .p-dialog-titlebar-close  {
    float: left;
}
.p-rtl .p-dialog .p-dialog-footer {
    text-align: left;
}
@media screen and (max-width: 40em) {
    .p-confirmdialog {
        width: 90%;
    }
}
/* Animation */
/* Center */
.p-dialog-enter {
    opacity: 0;
    transform: scale(0.7);
}

.p-dialog-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
}

.p-dialog-enter-done {
    transform: none;
}

.p-dialog-exit-active {
    opacity: 0;
    transform: scale(0.7);
    transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

/* Top, Bottom, Left, Right, Top* and Bottom* */
.p-dialog-top .p-dialog,
.p-dialog-bottom .p-dialog,
.p-dialog-left .p-dialog,
.p-dialog-right .p-dialog,
.p-dialog-topleft .p-dialog,
.p-dialog-topright .p-dialog,
.p-dialog-bottomleft .p-dialog,
.p-dialog-bottomright .p-dialog {
    margin: .75em;
    transition: all .3s ease-out;
}

.p-dialog-top .p-dialog-enter,
.p-dialog-top .p-dialog-exit-active  {
    transform: translate3d(0px, -100%, 0px);
}

.p-dialog-bottom .p-dialog-enter,
.p-dialog-bottom .p-dialog-exit-active {
    transform: translate3d(0px, 100%, 0px);
}

.p-dialog-left .p-dialog-enter,
.p-dialog-left .p-dialog-exit-active,
.p-dialog-topleft .p-dialog-enter,
.p-dialog-topleft .p-dialog-exit-active,
.p-dialog-bottomleft .p-dialog-enter,
.p-dialog-bottomleft .p-dialog-exit-active {
    transform: translate3d(-100%, 0px, 0px);
}

.p-dialog-right .p-dialog-enter,
.p-dialog-right .p-dialog-exit-active,
.p-dialog-topright .p-dialog-enter,
.p-dialog-topright .p-dialog-exit-active,
.p-dialog-bottomright .p-dialog-enter,
.p-dialog-bottomright .p-dialog-exit-active {
    transform: translate3d(100%, 0px, 0px);
}

.p-dialog-top .p-dialog-enter-active,
.p-dialog-bottom .p-dialog-enter-active,
.p-dialog-left .p-dialog-enter-active,
.p-dialog-topleft .p-dialog-enter-active,
.p-dialog-bottomleft .p-dialog-enter-active,
.p-dialog-right .p-dialog-enter-active,
.p-dialog-topright .p-dialog-enter-active,
.p-dialog-bottomright .p-dialog-enter-active {
    transform: translate3d(0px, 0px, 0px);
}

/* Maximize */
.p-dialog-maximized {
    -webkit-transition: none;
    transition: none;
    transform: none;
    width: 100vw !important;
    max-height: 100%;
    height: 100%;
}
.p-dialog-maximized .p-dialog-content {
    flex-grow: 1;
}

/* Position */
.p-dialog-left {
    justify-content: flex-start;
}
.p-dialog-right {
    justify-content: flex-end;
}
.p-dialog-top {
    align-items: flex-start;
}
.p-dialog-topleft {
    justify-content: flex-start;
    align-items: flex-start;
}
.p-dialog-topright {
    justify-content: flex-end;
    align-items: flex-start;
}
.p-dialog-bottom {
    align-items: flex-end;
}
.p-dialog-bottomleft {
    justify-content: flex-start;
    align-items: flex-end;
}
.p-dialog-bottomright {
    justify-content: flex-end;
    align-items: flex-end;
}

.p-fieldset, 
.p-fieldset .p-fieldset-legend {
    padding: 0.5em 1em;
}

.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
}

.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.5em 1em;
    cursor:pointer;
    white-space: nowrap;
    display: block;
}

.p-fieldset .p-fieldset-toggler {
    margin-right: .1em;
    display: inline-block;
    vertical-align: middle;
}

.p-fieldset .p-fieldset-legend-text {
    vertical-align: middle;
}

.p-fieldset-content-wrapper-collapsed {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.p-fieldset-content-wrapper-expanded {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
}

.p-fieldset-content-wrapper-expanding {
    overflow: hidden;
}
.p-dropdown {
    display: inline-block;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
}

.p-dropdown .p-dropdown-hidden-select {
    bottom: 0;
    clip: auto;
}

.p-dropdown .p-dropdown-hidden-select select {
    -webkit-transform: none;
    transform: none;
    height: 1px;
    position: absolute;
    top: 0;
    clip: rect(0px, 0px, 0px, 0px);
    pointer-events: none;
}

.p-dropdown .p-dropdown-clear-icon {
    position: absolute;
    right: 2em;
    top: 50%;
    font-size: 1em;
    height: 1em;
    margin-top: -.5em;
}

.p-dropdown .p-dropdown-trigger {
    border-right: none;
    border-top: none;
    border-bottom: none;
    cursor: pointer;
    width: 1.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 .25em;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    position: absolute;
}

.p-dropdown .p-dropdown-label  {
    display: block;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    width: 100%;
    padding-right: 1.5em;
}

.p-dropdown .p-dropdown-item-empty,
.p-dropdown .p-dropdown-label-empty {
    overflow: hidden;
    visibility: hidden;
}

.p-dropdown.p-disabled .p-dropdown-trigger,
.p-dropdown.p-disabled .p-dropdown-label {
    cursor: default;
}

.p-dropdown label.p-dropdown-label  {
    cursor: pointer;
}

.p-dropdown input.p-dropdown-label  {
    cursor: default;
}

.p-dropdown .p-dropdown-panel {
    min-width: 100%;
}

.p-dropdown-panel {
    position: absolute;
    height: auto;
}

.p-dropdown-panel .p-dropdown-items-wrapper {
    overflow: auto;
}

.p-dropdown-panel .p-dropdown-item {
    font-weight: normal;
    border: 0 none;
    cursor: pointer;
    margin: 1px 0;
    padding: .125em .25em;
    text-align: left;
}

.p-dropdown-panel .p-dropdown-item-group {
    font-weight: bold;
}

.p-dropdown-panel .p-dropdown-list {
    padding: 0.4em;
    border: 0 none;
    margin: 0;
    list-style-type: none;
}

.p-dropdown-panel .p-dropdown-filter {
    width: 100%;
    box-sizing: border-box;
    padding-right: 1.5em;
}

.p-dropdown-panel .p-dropdown-filter-container {
    position: relative;
    margin: 0;
    padding: 0.4em;
    display: inline-block;
}

.p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
    position: absolute;
    top: .8em;
    right: 1em;
}

/** Dropdown **/
.p-fluid .p-dropdown {
    width: 100%;
    min-width: 100%;
}

/*
 * FileUpload
 */
.p-fileupload-buttonbar .p-fileupload-choose.p-disabled input {
    cursor: default;
}

.p-fileupload-buttonbar {
    padding: .5em;
    border-bottom: 0 none;
}

.p-fileupload-buttonbar .p-button {
    vertical-align: middle;
    margin-right: .25em;
}

.p-fileupload-content {
    padding: 1em;
    position: relative;
    transition: border-color .3s;
}

.p-fileupload-content.p-fileupload-highlight {
    border-color: #156090;
}

.p-fileupload-files img {
    border: none;
}

.p-fileupload-files {
    display: table;
}

.p-fileupload-row {
    display: table-row;
}

.p-fileupload-row > div {
    display: table-cell;
    padding: .5em 1em;
    vertical-align: middle;
}

.p-fileupload-content .p-progressbar {
    width: 100%;
    position: absolute;
    top: 1px;
    left: 0;
    height: .25em;
    border: 0 none;
}

.p-fileupload-content .p-progressbar-value {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
}

/* Simple */
.p-fileupload-choose {
    position: relative;
    overflow: hidden;
}

.p-fileupload-choose input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    direction: ltr;
    cursor: pointer;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type=file] {
    display: none;
}

/* p-fluid */
.p-fluid .p-fileupload .p-button {
    width: auto;
}

.p-fluid .p-fileupload-content .p-button-icon-only {
    width: 2em;
}
.p-galleria-content {
    display: flex;
    flex-direction: column;
}

/* Preview */
.p-galleria-preview-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}
.p-galleria-preview-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}
.p-galleria-preview-container .p-galleria-preview-nav-button {
    height: 3em;
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    z-index: 1;
}
.p-galleria-preview-prev {
    left: .5em;
}
.p-galleria-preview-next {
    right: .5em;
}
.p-galleria-preview-prev span,
.p-galleria-preview-next span {
    font-size: 2em;
}
.p-galleria-preview-items-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.p-galleria-preview-nav-onhover .p-galleria-preview-content .p-galleria-preview-nav-button {
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease-in-out;
}
.p-galleria-preview-nav-onhover .p-galleria-preview-content:hover .p-galleria-preview-nav-button {
    pointer-events: all;
    opacity: 1;
}
.p-galleria-preview-nav-onhover .p-galleria-preview-content:hover .p-galleria-preview-nav-button.p-disabled {
    pointer-events: none;
    opacity: .5;
}
.p-galleria-preview-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    width: 100%;
}
.p-galleria-indicator-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .2em;
}

/* Thumbnails */
.p-galleria-thumbnail-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    flex-shrink: 0;
}
.p-galleria-thumbnail-prev,
.p-galleria-thumbnail-next {
    align-self: center;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 2.5em;
    height: 2.5em;
}
.p-galleria-thumbnail-prev span,
.p-galleria-thumbnail-next span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-galleria-thumbnail-container {
    display: flex;
    flex-direction: row;
    padding: 0 .1em;
}
.p-galleria-thumbnail-items-content {
    overflow: hidden;
    width: 100%;
}
.p-galleria-thumbnail-items-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}
.p-galleria-thumbnail-items-container .p-galleria-thumbnail-item {
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-galleria-thumbnail-item .p-galleria-thumbnail-item-content {
    margin: .3em;
    cursor: pointer;
}

/* Indicators */
.p-galleria-indicator-onpreview .p-galleria-indicator-container {
    position: absolute;
    z-index: 1;
}
.p-galleria-indicator-onpreview.p-galleria-indicators-top .p-galleria-indicator-container {
    top: 1em;
    left: 0;
    width: 100%;
}
.p-galleria-indicator-onpreview.p-galleria-indicators-right .p-galleria-indicator-container {
    right: 1em;
    top: 0;
    height: 100%;
}
.p-galleria-indicator-onpreview.p-galleria-indicators-bottom .p-galleria-indicator-container {
    bottom: 1em;
    left: 0;
    width: 100%;
}
.p-galleria-indicator-onpreview.p-galleria-indicators-left .p-galleria-indicator-container {
    left: 1em;
    top: 0;
    height: 100%;
}
.p-galleria-indicator-onpreview.p-galleria-indicators-left .p-galleria-preview-caption,
.p-galleria-indicator-onpreview.p-galleria-indicators-right .p-galleria-preview-caption {
    width: calc(100% - 4em);
}
.p-galleria-indicator-onpreview.p-galleria-indicators-right .p-galleria-preview-caption,
.p-galleria-indicator-onpreview.p-galleria-indicators-right .p-galleria-preview-next {
    right: 4em;
}
.p-galleria-indicator-onpreview.p-galleria-indicators-left .p-galleria-preview-caption,
.p-galleria-indicator-onpreview.p-galleria-indicators-left .p-galleria-preview-prev {
    left: 4em;
}

/* Positions */
/* Thumbnails */
.p-galleria-thumbnails-left .p-galleria-content,
.p-galleria-thumbnails-right .p-galleria-content {
    flex-direction: row;
    align-items: center;
}
.p-galleria-thumbnails-left .p-galleria-content .p-galleria-preview-content,
.p-galleria-thumbnails-right .p-galleria-content .p-galleria-preview-content {
    flex-direction: row;
}
.p-galleria-thumbnails-left .p-galleria-content .p-galleria-preview-content,
.p-galleria-thumbnails-top .p-galleria-content .p-galleria-preview-content {
    order: 2;
}
.p-galleria-thumbnails-left .p-galleria-content .p-galleria-thumbnail-content,
.p-galleria-thumbnails-top .p-galleria-content .p-galleria-thumbnail-content {
    order: 1;
}
.p-galleria-thumbnails-left .p-galleria-content .p-galleria-thumbnail-container,
.p-galleria-thumbnails-right .p-galleria-content .p-galleria-thumbnail-container {
    flex-direction: column;
}
.p-galleria-thumbnails-left .p-galleria-content .p-galleria-thumbnail-items-container,
.p-galleria-thumbnails-right .p-galleria-content .p-galleria-thumbnail-items-container {
    flex-direction: column;
    height: 100%;
}
/* Indicators */
.p-galleria-indicators-left .p-galleria-preview-content,
.p-galleria-indicators-right .p-galleria-preview-content {
    flex-direction: row;
    align-items: center;
}
.p-galleria-indicators-left .p-galleria-preview-content .p-galleria-preview-container,
.p-galleria-indicators-top .p-galleria-preview-content .p-galleria-preview-container {
    order: 2;
}
.p-galleria-indicators-left .p-galleria-preview-content .p-galleria-indicator-container,
.p-galleria-indicators-top .p-galleria-preview-content .p-galleria-indicator-container {
    order: 1;
}
.p-galleria-indicators-left .p-galleria-preview-content .p-galleria-indicator-container,
.p-galleria-indicators-right .p-galleria-preview-content .p-galleria-indicator-container {
    flex-direction: column;
}

/* FullScreen */
.p-galleria-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
}
.p-galleria-mask .p-galleria {
    max-height: 90%;
    max-width: calc(90% - 8em);
}
.p-galleria-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: .5em;
}
.p-galleria-close span {
    font-size: 3em;
}
.p-galleria-mask .p-galleria .p-galleria-preview-nav-button {
    position: fixed;
    top: 50%;
    height: 20em;
    width: 4em;
    margin-top: -10em;
}
.p-galleria-mask .p-galleria .p-galleria-preview-prev {
    left: 0;
}
.p-galleria-mask .p-galleria .p-galleria-preview-next {
    right: 0;
}

/* Keyboard Support */
.p-items-hidden .p-galleria-thumbnail-item {
    visibility: hidden;
}

.p-items-hidden .p-galleria-thumbnail-item.p-galleria-thumbnail-item-active {
    visibility: visible;
}

.ui-fluid .fc .ui-button {
    width: auto;
}
.p-inplace .p-inplace-display {
    display: inline;
    cursor: pointer;
    border: 0 none;
    padding: .25em;
    font-weight: normal;
}

.p-inplace .p-inplace-content {
    display: inline;
}
.p-growl {
    position:fixed;
	width: 20em;
}

.p-growl.p-growl-topright {
	top: 20px;
	bottom: auto;
	right: 20px;
}

.p-growl.p-growl-topleft {
	top: 20px;
	bottom: auto;
	left: 20px;
}

.p-growl.p-growl-bottomleft {
	top: auto;
	bottom: 20px;
	left: 20px;
}

.p-growl.p-growl-bottomright {
	top: auto;
	bottom: 20px;
	right: 20px;
}

.p-growl-item-container {
	position:relative;
	margin:0 0 10px 0;
}

.p-growl-item {
	position: relative;
    padding: .5em 1em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.p-growl-item p {
	padding: 0;
	margin: 0;
}

.p-growl-icon-close {
	position: absolute;
	top: 4px;
	right: 4px;
	cursor: pointer;
}

.p-growl-title {
	font-weight: bold;
	display: block;
}

.p-growl-details {
    margin-top: .5em;
}

.p-growl-image {
	font-size: 2em;
}

.p-growl-message {
    margin-left: 1em;
    align-self: center;
}

.p-growl-message p {
	font-weight: normal;
}

/* Animations */
.p-growl-enter {
    opacity: 0.01;
}

.p-growl-enter.p-growl-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.p-growl-exit {
	max-height: 500px;
}

.p-growl-exit.p-growl-exit-active {
	max-height: 0;
	overflow: hidden;
	margin-bottom: 0;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), margin-bottom 0.5s cubic-bezier(0, 1, 0, 1);
}

.p-inputswitch {
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.75em;
}

.p-inputswitch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 30px;
}

.p-inputswitch-slider:before {
    position: absolute;
    content: "";
    height: 1.250em;
    width: 1.250em;
    left: .25em;
    bottom: .25em;
    border-radius: 50%;
    -webkit-transition: .3s;
    transition: .3s;
}

.p-inputswitch-checked .p-inputswitch-slider:before {
    -webkit-transform: translateX(1.250em);
    -ms-transform: translateX(1.250em);
    transform: translateX(1.250em);
}
.p-inputtext {
    margin: 0;
    outline: medium none;
    padding: .25em;
    font-weight: normal;
}

.p-fluid .p-inputtext {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.p-inputgroup {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.p-inputgroup .p-inputgroup-addon {
    display: inline-block;
    text-align: center;
    min-width: 1.5em;
    padding: .25em;
    border-width: 1px;
    border-style: solid;
}

.p-inputgroup .p-inputgroup-addon + .p-inputgroup-addon {
    border-left: 0 none;
}

.p-inputgroup .p-inputtext {
    padding-left: .5em;
}

.p-inputgroup > .p-inputtext:not(:first-child),
.p-inputgroup > .p-inputtextarea:not(:first-child),
.p-inputgroup > .p-calendar:not(:first-child) > .p-inputtext,
.p-inputgroup > .p-chips:not(:first-child) > .p-inputtext,
.p-inputgroup > .p-dropdown:not(:first-child),
.p-inputgroup > .p-multiselect:not(:first-child),
.p-inputgroup > .p-selectbutton:not(:first-child) > .p-button,
.p-inputgroup > .p-autocomplete:not(:first-child) > .p-autocomplete-input,
.p-inputgroup > .p-spinner:not(:first-child),
.p-inputgroup > .p-spinner:not(:first-child) > .p-spinner-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 none;
}

.p-inputgroup > .p-inputtext:not(:last-child),
.p-inputgroup > .p-inputtextarea:not(:last-child),
.p-inputgroup > .p-calendar:not(:last-child) > .p-inputtext,
.p-inputgroup > .p-chips:not(:last-child) > .p-inputtext,
.p-inputgroup > .p-dropdown:not(:last-child),
.p-inputgroup > .p-multiselect:not(:last-child),
.p-inputgroup > .p-selectbutton:not(:last-child) > .p-button,
.p-inputgroup > .p-autocomplete:not(:last-child) > .p-autocomplete-input,
.p-inputgroup > .p-spinner:not(:last-child),
.p-inputgroup > .p-spinner:not(:last-child) > .p-spinner-input,
.p-inputgroup > .p-spinner:not(:last-child) > .p-spinner-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
}

.p-inputgroup .p-button {
    margin-right: 0;
    border-radius: 0;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}

.p-fluid .p-inputgroup .p-inputtext {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.p-inputgroup .p-checkbox, 
.p-inputgroup .p-radiobutton {
    margin-right: 0;
    vertical-align: bottom;
}

/* Floating Label */
.p-float-label > input:focus ~ label,
.p-float-label > input.p-filled ~ label,
.p-float-label > .p-inputwrapper-focus ~ label,
.p-float-label > .p-inputwrapper-filled ~ label {
  top:-.75em;
  font-size:12px;
}

.p-float-label > input:-webkit-autofill ~ label {
  top:-.75em;
  font-size:12px;
}
.p-inputnumber {
    display: inline-flex;
}

.p-inputnumber-button-up,
.p-inputnumber-button-down {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}

.p-inputnumber-input {
    flex: 1 1 auto;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-up {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-down {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
    display: flex;
    flex-direction: column;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-inputnumber-button {
    flex: 1 1 auto;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-inputnumber-button .p-inputnumber-button-icon {
    font-size: inherit;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-button-up {
    order: 3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
    order: 2;
    border-radius: 0;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-button-down {
    order: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-inputnumber-buttons-vertical {
    flex-direction: column;
}

.p-inputnumber-buttons-vertical .p-inputnumber-button-up {
    order: 1;
    flex: 1 1 auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: auto;
}

.p-inputnumber-buttons-vertical .p-inputnumber-input {
    order: 2;
    border-radius: 0;
    text-align: center;
}

.p-inputnumber-buttons-vertical .p-inputnumber-button-down {
    order: 3;
    flex: 1 1 auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: auto;
}

.p-fluid .p-inputnumber {
    width: 100%;
}

.p-fluid .p-inputnumber .p-inputnumber-input {
    width: 1%;
}

.p-fluid .p-inputnumber-buttons-vertical .p-inputnumber-input,
.p-fluid .p-inputnumber-buttons-vertical .p-inputnumber-button {
    width: 100%;
}
.p-inputtextarea-resizable {
    overflow: hidden;
    resize: none;
}

.p-fluid .p-inputtextarea {
    width: 100%;
}

/* Floating Label */
.p-float-label > textarea ~ label {
  left: .25em;
  top: .75em;
}

.p-float-label > textarea:focus ~ label,
.p-float-label > textarea.p-filled ~ label {
  top:-.75em;
  font-size:12px;
}

.p-float-label > textarea:-webkit-autofill ~ label {
  top:-.75em;
  font-size:12px;
}
.p-lightbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.p-lightbox-content-wrapper {
    position: relative;
}

.p-lightbox-content {
    position: relative;
    margin: 0;
    padding: 0;
    background-color: #000000;
    -webkit-transition-property: width, height;
    -moz-transition-property: width, height;
    -ms-transition-property: width, height;
    -o-transition-property: width, height;
    transition-property: width, height;
}

.p-lightbox-nav-right, .p-lightbox-nav-left {
   position: absolute;
   top: 50%;
   cursor: pointer;
   z-index: 1;
}

.p-lightbox-nav-left {
   left: 0;
}

.p-lightbox-nav-right {
   right: 0;
}

.p-lightbox-loading .p-lightbox-content {
    background: url("./images/loading.gif") #000000 center center no-repeat;
}

.p-lightbox-caption {
    padding: 0.2em 0.4em;
    display: none;
}

.p-lightbox-caption-text {
    margin: 0.3em 0 0.1em 0;
    float:left;
}

.p-lightbox-close {
    float:right;
    margin: 0;
    padding: .125em;
}

.p-lightbox-close.p-state-hover {
    padding: 0;
}

.p-lightbox-nav-left, .p-lightbox-nav-right {
    opacity: .5;
}

.p-lightbox-nav-left:hover, .p-lightbox-nav-right:hover{
    opacity: 1;
}
.p-listbox {
    padding: .25em;
    width: 10em;
}

.p-listbox .p-listbox-list-wrapper {
    overflow:auto;
}

.p-listbox .p-listbox-list {
    list-style-type: none; 
    margin: 0;
    padding: 0;
}

.p-listbox .p-listbox-item {
    padding: .25em;
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 1px;
}

.p-listbox .p-listbox-item > span {
    vertical-align: middle;
}

.p-listbox .p-listbox-item:last-child {
    margin-bottom: 0;
}

.p-listbox.p-disabled .p-listbox-item {
    cursor: default;
}

.p-listbox-header {
    margin-bottom: 0.3em;
    padding: .125em .2em;
    position: relative;
}

.p-listbox-header .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.p-listbox-header .p-listbox-filter-container {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.p-listbox-header.p-listbox-header-w-checkbox .p-listbox-filter-container {
    width: calc(100% - 2em);
}

.p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
    position: absolute;
    top: .25em;
    left: .25em;
}

.p-listbox-header .p-inputtext {
    padding: .125em .125em .125em 1.25em;
    width: 100%;
}
.p-megamenu {
    padding: .25em;
}

.p-megamenu-root-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.p-megamenu-root-list > .p-menuitem {
    position: relative;
}

.p-megamenu .p-menuitem-link {
    padding: .25em;
    display: block;
    text-decoration: none;
}

.p-megamenu .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-megamenu .p-menuitem-text {
    vertical-align: middle;
}

.p-megamenu-panel {
    display: none;
    position: absolute;
    width: auto;
    z-index: 1;
}

.p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
    display: block;
}

.p-megamenu-panel .p-menuitem {
    margin: .125em 0;
}

.p-megamenu-submenu {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 12.5em;
}

.p-megamenu-submenu-header {
    padding: .25em;
}

/* Horizontal */
.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem {
    display: inline-block;
}

.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    vertical-align: middle;
    margin-left: .25em;
}

/* Vertical */
.p-megamenu-vertical {
    width: 12.5em;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem {
    display: block;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    position: relative;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
    left: 100%;
    top: 0;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    right: 0;
    margin-top: -.5em;
}

.p-megamenu .p-grid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.p-menu {
    width: 12.5em;
    padding: .25em;
}

.p-menu.p-menu-dynamic {
    position: absolute;
    display: none;
}

.p-menu .p-menu-separator {
    border-width: 1px 0 0 0;
}

.p-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-menu .p-submenu-header {
    padding: .25em .5em;
    margin: .125em 0;
}

.p-menu .p-menuitem {
    margin: .125em 0;
}

.p-menu .p-menuitem-link {
    padding: .25em;
    display: block;
    text-decoration: none;
}

.p-menu .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-menu .p-menuitem-text {
    vertical-align: middle;
}
.p-menubar {
    padding: .25em;
}

.p-menubar .p-menu-separator {
    border-width: 1px 0 0 0;
}

.p-menubar:after {
    content: "";
    clear: both;
    display: table;
}

.p-menubar ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.p-menubar .p-menuitem-link {
    display: block;
    padding: .25em;
    position: relative;
    text-decoration: none;
}

.p-menubar .p-menuitem-icon {
    margin-right: .25em;
}

.p-menubar .p-menubar-root-list {
    display: inline-block;
}

.p-menubar .p-menubar-root-list > .p-menuitem {
    display: inline-block;
    position: relative;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: .5em;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    vertical-align: middle;
    margin-left: .25em;
}

.p-menubar .p-menubar-root-list > li ul {
    display: none;
    z-index: 1;
}

.p-menubar .p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
    display: block;
}

.p-menubar .p-submenu-list {
    display: none;
    position: absolute;
    min-width: 12.5em;
    padding: .25em;
}

.p-menubar .p-submenu-list .p-menuitem {
    margin: .125em 0;
    position: relative;
}

.p-menubar .p-submenu-list > .p-menuitem-active > .p-submenu-list  {
    display: block;
    left: 100%;
    top: 0;
}

.p-menubar .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-menubar .p-menuitem-text {
    vertical-align: middle;
}

.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -.5em;
    right: 0;
    top: 50%;
}

.p-menubar .p-menuitem-active > .p-submenu > .p-submenu-list {
    display: block;
}

.p-menubar .p-menubar-custom {
    float: right;
    padding: .25em;
}
.p-message {
    border: 1px solid;
    margin: 0px .25em;
    padding: .25em .5em;
    display: inline-block;
    vertical-align: top;
}

.p-message .p-message-icon,
.p-message .p-message-text {
    vertical-align: middle;
}

.p-fluid .p-message {
    display: block;
}
/** MultiSelect **/
.p-multiselect {
    display: inline-block;
    position: relative;
    width: auto;
    cursor: pointer;
}

.p-multiselect .p-multiselect-trigger {
    border-right: none;
    border-top: none;
    border-bottom: none;
    cursor: pointer;
    width: 1.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 .25em;
}

.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    position: absolute;
}

.p-multiselect .p-multiselect-label-container  {
    overflow: hidden;
}

.p-multiselect .p-multiselect-label  {
    display: block;
    padding: .25em 2em .25em .25em;
    width: auto;
    border: none;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.p-multiselect .p-multiselect-label-empty {
    overflow: hidden;
    visibility: hidden;
}

.p-multiselect.p-disabled .p-multiselect-trigger,
.p-multiselect.p-disabled .p-multiselect-label {
    cursor: auto
}

.p-multiselect-panel {
    padding: 0.2em;
    position: absolute;
    min-width: 10em;
}

.p-multiselect .p-multiselect-panel {
    min-width: 100%;
}

.p-multiselect-panel .p-multiselect-items-wrapper {
    overflow: auto;
    position: relative;
    padding: 0.2em 0;
}

.p-multiselect-panel .p-multiselect-list {
    border: 0 none;
    margin: 0;
    list-style-type: none;
}

.p-multiselect-panel .p-multiselect-item {
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin: 1px 0;
    padding: .125em .25em;
    text-align: left;
    white-space: nowrap;
    display: block;
    position: relative;
}

.p-multiselect-panel .p-multiselect-item .p-checkbox {
    display: inline-block;
    vertical-align: middle;
}

.p-multiselect-panel .p-multiselect-item label {
    display: inline-block;
    vertical-align: middle;
}

.p-multiselect-header {
    margin-bottom: 0.3em;
    padding: .25em;
    position: relative;
    text-align: left;
}

.p-multiselect-header .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    cursor:pointer;
}

.p-multiselect-header .p-multiselect-filter-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 65%;
}

.p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    position: absolute;
    top: .25em;
    left: .125em;
}

.p-multiselect-header .p-inputtext {
    padding: .125em .125em .125em 1.25em;
    width: 100%;
}

.p-multiselect-header .p-multiselect-close {
    position: absolute;
    right: .375em;
    top: .375em;
    display: block;
    border: 0 none;
}

.p-multiselect-header a.p-multiselect-all,
.p-multiselect-header a.p-multiselect-none {
    float:left;
    margin-right: 10px;
    display: block;
}

.p-multiselect-header .p-multiselect-close.p-state-hover {
    padding:0px;
}

.p-fluid .p-multiselect {
    width: 100%;
    box-sizing: border-box;
}

.p-messages {
    position: relative;
}

.p-messages .p-messages-wrapper {
    padding: 1em;	
}

.p-messages .p-messages-icon {
    display:inline-block;
    padding: 0;
    vertical-align: middle;
    font-size: 2em;
}

.p-messages .p-messages-summary {
    font-weight: bold;
    margin-left: .25em;
}

.p-messages .p-messages-detail {
    margin-left: .25em;
}

.p-messages ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;
    vertical-align: middle;
}

.p-messages.p-messages-noicon ul {
    margin: 0 1.5em 0 0;
}

.p-messages .p-messages-close {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

.p-messages-exit .p-messages-close {
    display: none;
}

/* Animations */	
.p-messages-enter {	
    opacity: 0.01;	
}
.p-messages-enter-active {	
    opacity: 1;
    transition: opacity 250ms ease-in;	
}	
.p-messages-exit {	
    max-height: 500px;	
}	
.p-messages-exit.p-messages-exit-active {	
    max-height: 0;	
    overflow: hidden;	
    margin-bottom: 0;	
    transition: max-height 500ms cubic-bezier(0, 1, 0, 1), margin-bottom 500ms cubic-bezier(0, 1, 0, 1);	
} 
.p-orderlist {
    display: table;
}

.p-orderlist .p-orderlist-controls {
    height: 12.5em;
    padding: 0 .25em;
    vertical-align: middle;
    display: table-cell;
}

.p-orderlist .p-orderlist-controls .p-button {
    display: block;
    margin-bottom: 0.25em;
}

.p-orderlist .p-orderlist-list-container {
    display: table-cell;
    vertical-align: top;
}

.p-orderlist .p-orderlist-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow:auto;
    height: 12.5em;
    width: 12.5em;
}

.p-orderlist .p-orderlist-caption {
    text-align: center;
    padding: .5em .75em;
    border-bottom: 0 none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    margin: 1px;
    padding: .125em;
    cursor: pointer;
    border: 0 none;
    font-weight: inherit;
}

.p-orderlist .p-orderlist-filter-container {
    position: relative;
    width: 100%;
    padding: .5em .6em;
    border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-inputtext {
    text-indent: 1.1em;
    width: 100%;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    position: absolute;
    top: 50%;
    left: 1em;
    margin-top: -.6em;
}

.p-orderlist.p-disabled .p-orderlist-item,
.p-orderlist.p-disabled .p-button {
    cursor: default;
}

.p-orderlist.p-disabled .p-orderlist-list {
    overflow:hidden;
}

/* Responsive */
.p-orderlist.p-orderlist-responsive {
    width: 100%;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-controls {
    width: 16.66666%;
    padding-right: .5em;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-list-container {
    width: 83.33333%;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-list,
.p-orderlist.p-orderlist-responsive .p-orderlist-caption {
    width: 100%;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-controls > .p-button {
    width: 100%;
}

.p-orderlist .p-orderlist-droppoint {
    height: 6px;
    list-style-type: none;
}

@media (max-width: 40em) {
    .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
        text-align: center;
        width: 100%;
        display: block;
        height: auto;
    }

    .p-orderlist.p-orderlist-responsive .p-orderlist-controls .p-button {
        display: inline-block;
        width: 20%;
        margin-right: .25em;
    }

    .p-orderlist.p-orderlist-responsive .p-orderlist-list-container {
        width: 100%;
        display: block;
    }
}
.p-organizationchart .p-organizationchart-table {
    border-spacing: 0;
    border-collapse: separate;
    margin: 0 auto;
}

.p-organizationchart .p-organizationchart-table > tbody > tr > td {
    text-align: center;
    vertical-align: top;
    padding: 0;
    padding: 0 .75em;
}

.p-organizationchart .p-organizationchart-node-content {
    padding: .5em .75em;
    display: inline-block;
    position: relative;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    position: absolute;
    bottom: -9px;
    margin-left: -8px;
    z-index: 2;
    left: 50%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.p-organizationchart .p-organizationchart-line-down {
    margin: 0 auto;
    height: 20px;
    width: 1px;
    float: none;
}

.p-organizationchart .p-organizationchart-line-right {
    float: none;
    border-radius: 0px;
}

.p-organizationchart .p-organizationchart-line-left {
    float: none;
    border-radius: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node {
    cursor: pointer;
}
.p-overlaypanel {
    padding: 0;
    margin: 0;
    position: absolute;
    display: none;
    margin-top: 10px;
}

.p-overlaypanel-flipped {
    margin-top: 0;
    margin-bottom: 10px;
}

.p-overlaypanel-content {
    padding: 0.5em 1em;
}

.p-overlaypanel-close {
    position: absolute;
    top: -1em;
    right: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.p-overlaypanel-close-icon {
    line-height: inherit;
}

.p-overlaypanel:after, .p-overlaypanel:before {
	bottom: 100%;
	left: 1.25em;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.p-overlaypanel:after {
	border-width: 8px;
	margin-left: -8px;
}

.p-overlaypanel:before {
	border-width: 10px;
	margin-left: -10px;
}

.p-overlaypanel-flipped:after, .p-overlaypanel-flipped:before {
    bottom: auto;
    top: 100%;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-bottom-color: transparent;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-bottom-color: transparent
}
.p-panel {
	padding: 0.2em;
}

.p-panel .p-panel-titlebar {
	padding: .5em .75em;
}

.p-panel .p-panel-titlebar-icon {
	float: right;
    cursor: pointer;
    height: 1.25em;
    width: 1.25em;
    line-height: 1.25em;
    text-align: center;
}

.p-panel .p-panel-titlebar-icon span {
    line-height: inherit;
    margin-top: -1px;
}

.p-panel .p-panel-content {
	border: 0;
    background: none;
    padding: .5em .75em;
}

.p-panel .p-panel-footer {
	border-width: 1px 0 0;
	padding: .25em .5em;
	text-align:left;
}
.p-paginator {
    margin: 0;
    text-align: center;
    padding: .125em;
}

.p-paginator .p-paginator-top {
    border-bottom: 0 none;
}

.p-paginator .p-paginator-bottom {
    border-top:0 none;
}

.p-paginator .p-paginator-left-content {
    float: left;
}

.p-paginator .p-paginator-right-content {
    float: right;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-pages,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-current {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
	zoom: 1;
	margin-left: .063em;
	margin-right: .063em;
	text-decoration: none;
    vertical-align: middle;
    text-align: center;
    position: relative;
}

.p-paginator .p-paginator-pages {
    width: auto;
    line-height: 1;
}

.p-paginator .p-paginator-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -.5em;
    margin-left: -.5em;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev{
    cursor: pointer;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-rpp-options {
	margin-left: 1em;
	margin-right: 1em;
    background-image: none;
}

.p-paginator .p-paginator-jtp-select option,
.p-paginator .p-paginator-rpp-options option {
    background-image: none;
    border: 0 none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.p-paginator a.p-disabled {
    outline: 0 none;
}

.p-paginator .p-dropdown {
    min-width: 4em;
    margin-left: .375em;
}

.p-fluid .p-paginator .p-dropdown {
    width: auto;
}

.p-paginator .p-paginator-current {
    width: auto;
    height: auto;
}
.p-panelmenu {
    width: auto;
}

.p-panelmenu .p-menu-separator {
    border-width: 1px 0 0 0;
}

.p-panelmenu .p-panelmenu-content-wrapper {
    overflow: hidden;
}

.p-panelmenu .p-panelmenu-header {
    margin: -1px 0 0 0;
    zoom: 1;
}

.p-panelmenu .p-panelmenu-header-link {
    padding: .5em;
    display: block;
    text-decoration: none;
}

.p-panelmenu .p-panelmenu-icon {
    vertical-align: middle;
}

.p-panelmenu .p-menuitem-text {
    vertical-align: middle;
}

.p-panelmenu .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-panelmenu .p-panelmenu-content {
    padding: 0.25em;
    border-top: 0;
    margin-bottom: 1px;
}

.p-panelmenu .p-submenu-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 1.5em;
}

.p-panelmenu .p-panelmenu-content > .p-panelmenu-root-submenu.p-submenu-list {
    margin-left: 0;
}

.p-panelmenu .p-menuitem {
    overflow: hidden;
    margin: .125em 0;
}

.p-panelmenu .p-menuitem-link {
    padding: .25em;
    display: block;
    text-decoration: none;
}
.p-password-panel {
    padding: .25em .5em;
    margin-top: 2px;
}
 
.p-password-panel .p-password-meter {
    height: 10px;
    background:transparent url("./images/password-meter.png") no-repeat left top;
    padding: 0;
    margin: 0;
}
 
.p-password-info {
    margin-top: .25em;
}
 
.p-password-panel-overlay {
    position: absolute;
} 
.p-progressbar { 
    height: 1.2em; 
    text-align: left; 
    position: relative;
    overflow: hidden;
}

.p-progressbar-determinate .p-progressbar-value {
    height: 100%;
    width: 0%;
    position: absolute;
    display: none;
    border: 0 none;
}

.p-progressbar-determinate .p-progressbar-value-animate {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}

.p-progressbar-determinate .p-progressbar-label {
    text-align: center;
    height: 100%;
    width: 100%;
    position: absolute;
    font-weight: bold;
}

.p-progressbar-indeterminate {
    height: .5em;
}

.p-progressbar-indeterminate .p-progressbar-value {
    border: 0 none;
}

.p-progressbar-indeterminate .p-progressbar-value::before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.p-progressbar-indeterminate .p-progressbar-value::after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
            animation-delay: 1.15s;
}
    
@-webkit-keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } 
}
@keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } 
}

@-webkit-keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } 
}
@keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } 
}
.p-picklist > div {
    float: left;
}

.p-picklist .p-picklist-buttons {
    height: 12.5em;
    padding: 0 .25em;
}

.p-picklist .p-picklist-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow:auto;
    height: 12.5em;
    width: 12.5em;
}

.p-picklist .p-picklist-list li {
    margin: 1px;
    padding: .125em;
}

.p-picklist .p-button {
    display:block;
    margin-bottom: 0.25em;
}

.p-picklist .p-button-text-icon-left {
    width: 100%;
}

.p-picklist .p-picklist-item {
    cursor: pointer;
    border: 0 none;
    font-weight: inherit;
}

.p-picklist .p-picklist-caption {
    text-align: center;
    padding: .5em .75em;
    border-bottom:0 none;
}

.p-picklist table {
    width: 100%;
    border-collapse:collapse;
}

.p-picklist .p-picklist-filter-container {
    position: relative;
    width: 100%;
    padding: .5em .6em;
    border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter {
    text-indent: 1.1em;
    width: 100%;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    position: absolute;
    top: 50%;
    left: 1em;
    margin-top: -.6em;
}

.p-picklist {
    display: table;
}

.p-picklist > div {
    float: none;
    display: table-cell;
    vertical-align: top;
}

.p-picklist .p-picklist-buttons {
    vertical-align: middle;
}

/* Vertical */
.p-picklist.p-picklist-vertical {
    display: table;
}

.p-picklist.p-picklist-vertical > div {
    float: none;
    display: table-row;
    vertical-align: top;
}

.p-picklist.p-picklist-vertical .p-picklist-buttons {
    text-align:center;
    height: auto;
}

.p-picklist.p-picklist-vertical .p-picklist-buttons .p-button {
    display: inline-block;
}

.p-picklist.p-picklist-vertical .p-button {
    margin-top: 0.25em;
}

.p-picklist-outline {
    outline: 1px dotted black;
    z-index: 1;
}

.p-picklist .p-picklist-droppoint {
    height: 6px;
    list-style-type: none;
}

.p-picklist .p-picklist-list .p-picklist-droppoint-empty {
    height: 100%;
    list-style-type: none;
}

.p-picklist-list.p-picklist-source,
.p-picklist-list.p-picklist-target {
    outline: none;
}

/* Responsive */
.p-picklist.p-picklist-responsive * {
    box-sizing: border-box;
}

.p-picklist.p-picklist-responsive {
    width: 100%;
}

.p-picklist.p-picklist-responsive .p-picklist-listwrapper {
    width: 35%;
}

.p-picklist.p-picklist-responsive .p-picklist-listwrapper.p-picklist-listwrapper-nocontrols {
    width: 45%;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons {
    width: 10%;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons button {
    width: 100%;
}

.p-picklist.p-picklist-responsive .p-picklist-list {
    width: auto;
}

/* Responsive */
@media (max-width: 40em) {
    .p-picklist.p-picklist-responsive {
        display: block;
    }

    .p-picklist.p-picklist-responsive > div {
        display: block;
        width: 100% !important;
    }

    .p-picklist.p-picklist-responsive .p-picklist-buttons {
        text-align: center;
        height: auto;
        padding: .4em 0;
    }

    .p-picklist.p-picklist-responsive .p-picklist-buttons button {
        display: inline-block;
        width: 20%;
        margin-bottom: 0;
        margin-right: .25em;
    }

    .p-picklist.p-picklist-responsive .p-picklist-source-controls.p-picklist-buttons {
        padding-bottom: .4em;
    }

    .p-picklist.p-picklist-responsive .p-picklist-target-controls.p-picklist-buttons {
        padding-top: .4em;
    }
}

.p-progress-spinner {
    position: relative;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: inline-block;
}

.p-progress-spinner::before {
     content: '';
     display: block;
     padding-top: 100%;
}

.p-progress-spinner-svg {
    animation: p-progress-spinner-rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.p-progress-spinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #d62d20;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes p-progress-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes p-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

.p-radiobutton {
    display:inline-block;
    cursor: pointer;
    vertical-align: middle;
    margin-right: .25em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.p-radiobutton .p-radiobutton-box {
    width: 1.125em;
    height: 1.125em;
    line-height: 1.125em;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    position: relative;
}

.p-radiobutton .p-radiobutton-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -.5em;
    margin-left: -.5em;
}
 
.p-radiobutton + label {
    vertical-align: middle;
}
.p-rating .p-rating-icon {
    cursor: pointer;
}

.p-rating {
    font-size: 1.25em;
}

.p-rating.p-disabled .p-rating-icon,
.p-rating.p-rating-readonly .p-rating-icon {
    cursor: default;
}
.p-scrollpanel-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
}

.p-scrollpanel-content {
    height: calc(100% + 18px);
    width: calc(100% + 18px);
    padding: 0 18px 18px 0;
    position: relative;
    overflow: auto;
    box-sizing: border-box;
}

.p-scrollpanel-bar {
    position: relative;
    background: #c1c1c1;
    border-radius: 3px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s linear;
}

.p-scrollpanel-bar-y {
    width: 9px;
    top: 0;
}

.p-scrollpanel-bar-x {
    height: 9px;
    bottom: 0;
}

.p-scrollpanel-hidden {
    visibility: hidden;
}

.p-scrollpanel:hover .p-scrollpanel-bar,
.p-scrollpanel:active .p-scrollpanel-bar {
    opacity: 1;
}

.p-scrollpanel-grabbed {
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.p-selectbutton{
    display: inline-block;
}
            
.p-selectbutton.p-state-error {
    padding: 0;
}

.p-selectbutton .p-button.p-state-focus{
    outline: none;
}
.p-sidebar {
    position: fixed;
    padding: .5em 1em;
    -webkit-transition: transform .3s;
    transition: transform .3s;
}

.p-sidebar-left {
    top: 0;
    left: 0;
    width: 20em;
    height: 100%;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.p-sidebar-right {
    top: 0;
    right: 0;
    width: 20em;
    height: 100%;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.p-sidebar-top {
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.p-sidebar-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10em;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

.p-sidebar-full {
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-transition: transform 0s;
    transition: transform 0s;
}

.p-sidebar-full.p-sidebar-active {
    animation: p-fadein .25s;
    -moz-animation: p-fadein .25s; /* Firefox */
    -webkit-animation: p-fadein .25s; /* Safari and Chrome */
    -o-animation: p-fadein .25s; /* Opera */
}

.p-sidebar-left.p-sidebar-active,
.p-sidebar-right.p-sidebar-active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

.p-sidebar-left.p-sidebar-sm,
.p-sidebar-right.p-sidebar-sm {
    width: 20em;
}

.p-sidebar-left.p-sidebar-md,
.p-sidebar-right.p-sidebar-md {
    width: 40em;
}

.p-sidebar-left.p-sidebar-lg,
.p-sidebar-right.p-sidebar-lg {
    width: 60em;
}

.p-sidebar-top.p-sidebar-active,
.p-sidebar-bottom.p-sidebar-active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.p-sidebar-top.p-sidebar-sm,
.p-sidebar-bottom.p-sidebar-sm {
    height: 10em;
}

.p-sidebar-top.p-sidebar-md,
.p-sidebar-bottom.p-sidebar-md {
    height: 20em;
}

.p-sidebar-top.p-sidebar-lg,
.p-sidebar-bottom.p-sidebar-lg {
    height: 30em;
}

.p-sidebar-mask {
    position: fixed;
    width: 100%;
    height: 100%;
}

.p-sidebar-close {
    float: right;
    cursor: pointer;
}

@media screen and (max-width: 64em) {
    .p-sidebar-left.p-sidebar-lg,
    .p-sidebar-left.p-sidebar-md,
    .p-sidebar-right.p-sidebar-lg,
    .p-sidebar-right.p-sidebar-md {
        width: 20em;
    }
}
.p-slidemenu {
    width: 12.5em;
    padding: .25em;
}

.p-slidemenu.p-slidemenu-dynamic {
    position: absolute;
    display: none;
}

.p-slidemenu .p-menu-separator {
    border-width: 1px 0 0 0;
}

.p-slidemenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-slidemenu .p-slidemenu-rootlist {
    position: absolute;
    top: 0;
}

.p-slidemenu .p-submenu-list {
    display: none;
    position: absolute;
    top: 0;
    width: 12.5em;
    padding: .25em;
}

.p-slidemenu .p-menuitem-link {
    padding: .25em;
    display: block;
    position: relative;
    text-decoration: none;
}

.p-slidemenu .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-slidemenu .p-menuitem-text {
    vertical-align: middle;
}

.p-slidemenu .p-menuitem {
    position: relative;
    margin: .125em 0;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -.5em;
    right: 0;
    top: 50%;
}

.p-slidemenu .p-slidemenu-wrapper {
    position: relative;
}

.p-slidemenu .p-slidemenu-content {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    height: 100%;
}

.p-slidemenu-backward {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.25em;
    cursor: pointer;
}

.p-slidemenu-backward .p-slidemenu-backward-icon {
    vertical-align: middle;
}

.p-slidemenu-backward span {
    vertical-align: middle;
}

.p-slidemenu .p-menuitem-active {
    position: static;
}

.p-slidemenu .p-menuitem-active > .p-submenu-list {
    display: block;
}

.p-slider {
	position: relative;
	text-align: left;
}
.p-slider .p-slider-handle {
	position: absolute;
	width: 1.2em;
	height: 1.2em;
	cursor: default;
	-ms-touch-action: none;
    touch-action: none;
    z-index: 1;
}
.p-slider .p-slider-range {
	position: absolute;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

.p-slider .p-slider-handle.p-slider-handle-active {
    z-index: 2;
}

.p-slider-horizontal {
	height: .8em;
}
.p-slider-horizontal .p-slider-handle {
	top: -.3em;
	margin-left: -.6em;
}
.p-slider-horizontal .p-slider-range {
	top: 0;
	height: 100%;
}
.p-slider-vertical {
	width: .8em;
	height: 100px;
}
.p-slider-vertical .p-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}
.p-slider-vertical .p-slider-range {
	left: 0;
    width: 100%;
    bottom: 0;
}

.p-spinner {
    display: inline-block;
    overflow: visible;
    padding: 0;
    position: relative;
    vertical-align: middle;
}
.p-spinner-input {
    vertical-align: middle;
    padding-right: 1.5em;
}
.p-spinner-button {
    cursor: default;
    display: block;
    height: 50%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    vertical-align: middle;
    width: 1.5em;
}
.p-spinner .p-spinner-button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    width: 1em;
}
.p-spinner-button-up {
    top: 0;
}
.p-spinner-button-down {
    bottom: 0;
}

/* Fluid */
.p-fluid .p-spinner {
    width: 100%;
}
.p-fluid .p-spinner .p-spinner-input {
    padding-right: 2em;
    width: 100%;
}
.p-fluid .p-spinner .p-spinner-button {
    width: 1.5em;
}
.p-fluid .p-spinner .p-spinner-button .p-spinner-button-icon {
    left: .375em;
}
.p-splitbutton {
    position: relative;
    display: inline-block;
    zoom: 1;
}

.p-splitbutton .p-button.p-splitbutton-menubutton {
    width: 2em;
    vertical-align: top;
}

.p-splitbutton.p-disabled button {
    cursor: default;
}

.p-fluid .p-splitbutton {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.p-fluid .p-splitbutton .p-button:first-child {
    width: calc(100% - 2em);
}

.p-fluid .p-splitbutton .p-button.p-splitbutton-menubutton {
    width: 2em;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.p-splitbutton.p-button-secondary .p-button:first-child {
    border-right: 0 none;
}
.p-steps ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.p-steps .p-steps-item {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
}

.p-steps.p-steps-readonly .p-steps-item,
.p-steps .p-steps-item.p-disabled {
    cursor: auto;
    pointer-events: none;
}

.p-steps .p-steps-item .p-menuitem-link {
    text-decoration: none;
    padding: 1em;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.p-steps .p-steps-item.p-steps-current .p-menuitem-link,
.p-steps .p-steps-item.p-disabled .p-menuitem-link {
    cursor: default;
}

.p-steps .p-steps-number {
    font-size: 2em;
    display: block;
}

.p-steps .p-steps-title {
    display: block;
    white-space: nowrap;
}

/* Responsive */
@media (max-width: 40em) {
    .p-steps ul {
        flex-wrap: wrap;
    }

    .p-steps .p-steps-item .p-menuitem-link {
        padding: 0.5em;
    }
}

/** TabMenu **/
.p-tabmenu .p-tabmenu-nav { 
    margin: 0;
    padding: .25em .5em 0 .25em; 
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem { 
    list-style: none; 
    float: left; 
    position: relative; 
    margin: 0 .2em 1px 0;  
    padding: 0; 
    white-space: nowrap;
    display: block;
    border-bottom: 0;
    top: 1px; 
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem a { 
    float: left; 
    padding: 0.5em 1em;
    text-decoration: none; 
}

.p-tabmenu .p-tabmenu-nav a { 
    padding: 0.5em 1em;
}

.p-tabmenu .p-menuitem-icon  { 
    margin-right: .25em;
    vertical-align: middle;
}

.p-tabmenu .p-menuitem-text  { 
    vertical-align: middle;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-disabled a {
     cursor: default;
}
.p-tabview {
    padding: .25em; 
}

.p-tabview .p-tabview-nav { 
    margin: 0;
}

.p-tabview .p-tabview-nav:after { 
    content: "";
    display: table;
    clear: both;
}

.p-tabview .p-tabview-nav li { 
    list-style: none; 
    float: left; 
    position: relative; 
    margin: 0 .125em 1px 0;  
    padding: 0; 
    white-space: nowrap; 
}

.p-tabview .p-tabview-nav li a { 
    float: left; 
    padding: .5em 1em; 
    text-decoration: none; 
}

.p-tabview .p-tabview-nav li.p-tabview-selected a, 
.p-tabview .p-tabview-nav li.p-disabled a, 
.p-tabview .p-tabview-nav li.p-state-processing a { 
    cursor: text; 
}

.p-tabview .p-tabview-nav li a, 
.p-tabview.p-tabview-collapsible .p-tabview-nav li.p-tabview-selected a { 
    cursor: pointer; 
}

.p-tabview .p-tabview-panel { 
    border-width: 0; 
    padding: 1em; 
    background: none; 
}

.p-tabview .p-tabview-nav li { 
    display: block; 
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon,
.p-tabview .p-tabview-nav li .p-tabview-right-icon,
.p-tabview .p-tabview-nav li .p-tabview-title {
    vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-right-icon {
    margin-left: .25em;
    vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-close { 
    margin: 0.5em 0.3em 0 0; 
    cursor: pointer; 
}

/* per orientation settings */
/* top and bottom */
.p-tabview.p-tabview-top > .p-tabview-nav li { 
    border-bottom: 0;
    top: 1px; 
}

.p-tabview.p-tabview-top > .p-tabview-nav { 
    padding: .2em .2em 0; 
}

.p-tabview.p-tabview-bottom > .p-tabview-nav { 
    padding: 0 .2em .2em; 
}

.p-tabview.p-tabview-bottom > .p-tabview-nav li { 
    border-top: 0;
}

/* left and right*/
.p-tabview-left:after,
.p-tabview-right:after {
    clear:both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

.p-tabview-left > .p-tabview-nav {
    float:left;
    width: 25%;
    height: 300px;
    background-image: none;
    padding-top: 1px;
}

.p-tabview-left > .p-tabview-panels {
    float:right;
    width: 75%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li,
.p-tabview.p-tabview-right > .p-tabview-nav li{
    display: block;
    float: right;
    white-space: normal;
    width: 99%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li {
    margin: 0 0 1px 0;
    border-right:0 none;
}

.p-tabview.p-tabview-right > .p-tabview-nav {
    float:right;
    width: 25%;
    height: 300px;
    background-image: none;
    padding-top: 1px;
}

.p-tabview.p-tabview-right > .p-tabview-panels {
    float:left;
    width: 75%;
}

.p-tabview.p-tabview-right > .p-tabview-nav li {
    margin: 0 0 1px 0;
    border-left:0 none;
}
/* RTL */
.p-rtl .p-tabview .p-tabview-nav li {
    float: right;
}
.p-tieredmenu {
    width: 12.5em;
    padding: .25em;
}

.p-tieredmenu.p-tieredmenu-dynamic {
    position: absolute;
    display: none;
}

.p-tieredmenu .p-menu-separator {
    border-width: 1px 0 0 0;
}

.p-tieredmenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-tieredmenu .p-submenu-list {
    display: none;
    position: absolute;
    width: 12.5em;
    padding: .25em;
    z-index: 1;
}

.p-tieredmenu .p-menuitem-link {
    padding: .25em;
    display: block;
    position: relative;
    text-decoration: none;
}

.p-tieredmenu .p-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.p-tieredmenu .p-menuitem-text {
    vertical-align: middle;
}

.p-tieredmenu .p-menuitem {
    position: relative;
    margin: .125em 0;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -.5em;
    right: 0;
    top: 50%;
}

.p-tieredmenu .p-menuitem-active > .p-submenu-list {
    display: block;
    left: 100%;
    top: 0;
}
.p-toolbar {
    padding: .25em .5em;
}

.p-toolbar:after {
    content: "";
    display: table;
    clear: both;
}

.p-toolbar-group-left {
    float:left
}

.p-toolbar-group-right {
    float:right
}
.p-tooltip {
    position:absolute;
    display:none;
    padding: .25em .5em;
    max-width: 12.5em;
}

.p-tooltip.p-tooltip-right,
.p-tooltip.p-tooltip-left {
    padding: 0 .25em;
}

.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
    padding:.25em 0;
}

.p-tooltip .p-tooltip-text {
   padding: .125em .5em;
   background-color: rgb(76, 76, 76);
   color: #ffffff;
   white-space: pre-line;
}

.p-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.p-tooltip-right .p-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -.25em;
    border-width: .25em .25em .25em 0;
    border-right-color: rgb(76, 76, 76);
}

.p-tooltip-left .p-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -.25em;
    border-width: .25em 0 .25em .25em;
    border-left-color: rgb(76, 76, 76);
}

.p-tooltip.p-tooltip-top {
    padding: .25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -.25em;
    border-width: .25em .25em 0;
    border-top-color: rgb(76, 76, 76);
}

.p-tooltip-bottom .p-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -.25em;
    border-width: 0 .25em .25em;
    border-bottom-color: rgb(76, 76, 76);
}
.p-tree {
    width: 24em;
}

.p-tree .p-treenode-selectable.p-treenode-content {
    cursor: pointer;
}

.p-tree .p-tree-container {
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: .25em;
    white-space: nowrap;
}

.p-tree .p-treenode-children {
    margin: 0;
    padding: 0 0 0 1em;
}

.p-tree .p-treenode {
    background-attachment: scroll;
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat-y;
    list-style: none outside none;
    margin: 0;
    padding: .125em 0 0 0;
}

.p-tree .p-treenode-droppoint {
    height: 4px;
    list-style-type: none;
}

.p-tree .p-treenode-droppoint-active {
    border: 0 none;
}

.p-tree .p-tree-toggler {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.p-tree .p-tree-toggler .p-tree-toggler-icon {
    vertical-align: middle;
}

.p-tree .p-treenode-icon {
    display: inline-block;
    vertical-align: middle;
}

.p-tree .p-treenode-label {
    display: inline-block;
    padding: 0 .25em;
    vertical-align: middle;
}

.p-tree .p-treenode.p-treenode-leaf > .p-treenode-content > .p-tree-toggler {
    visibility: hidden;
}

.p-tree .p-checkbox-box {
    cursor: pointer;
}

.p-tree .p-checkbox {
    display: inline-block;
    vertical-align: middle;
}

.p-tree .p-checkbox .p-checkbox-icon {
    margin-left: 1px;
}

.p-tree .p-tree-filter {
    width: 100%;
    box-sizing: border-box;
    padding-right: 1.5em;
}

.p-tree .p-tree-filter-container {
    position: relative;
    margin: 0;
    padding: 0.4em;
    display: inline-block;
    width: 100%;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    position: absolute;
    top: .8em;
    right: 1em;
}

.p-tree.p-disabled .p-treenode-selectable.p-treenode-content,
.p-tree.p-disabled .p-tree-toggler,
.p-tree.p-disabled .p-checkbox-box {
    cursor: default;
}

/** Fluid **/
.p-fluid .p-tree {
    width: 100%;
}

/** Horizontal Tree **/
.p-tree-horizontal {
    width:auto;
    padding: .5em 0;
    overflow:auto;
}

.p-tree.p-tree-horizontal table,
.p-tree.p-tree-horizontal tr,
.p-tree.p-tree-horizontal td {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

.p-tree.p-tree-horizontal .p-tree-toggler {
    vertical-align: middle;
    margin: 0;
}

.p-tree-horizontal .p-treenode-content {
    font-weight: normal;
    padding: 0.4em 1em 0.4em 0.2em;
}

.p-tree.p-tree-horizontal .p-tree-node-label {
    margin: 0;
}

.p-tree-horizontal .p-treenode-parent .p-treenode-content {
    font-weight: normal;
    white-space: nowrap;
}

.p-tree.p-tree-horizontal .p-treenode {
    background: url("./images/line.gif") repeat-x scroll center center transparent;
    padding: .25em 2.5em;
}

.p-tree.p-tree-horizontal .p-treenode.p-treenode-leaf,
.p-tree.p-tree-horizontal .p-treenode.p-treenode-collapsed {
    padding-right: 0;
}

.p-tree.p-tree-horizontal .p-treenode-children {
    padding: 0;
    margin: 0;
}

.p-tree.p-tree-horizontal .p-treenode-connector {
    width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-table {
    height: 100%;
    width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-line {
    background: url("./images/line.gif") repeat-y scroll 0 0 transparent;
    width: 1px;
}

.p-tree.p-tree-horizontal table {
	height: 0;
}

.p-tree.p-tree-horizontal .p-checkbox {
    vertical-align: bottom;
    margin-right: .25em;
}

/** Loading **/
.p-tree.p-tree-loading {
    position: relative;
    min-height: 4em;
}

.p-tree .p-tree-loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.p-tree .p-tree-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.p-tree .p-tree-loading-content .p-tree-loading-icon {
    font-size: 2em;
} 

.p-treetable {
    position: relative;
}

.p-treetable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.p-treetable-toggler {
    cursor: pointer;
    display: inline-block;
}

.p-treetable .p-treetable-thead > tr > th,
.p-treetable .p-treetable-tbody > tr > td,
.p-treetable .p-treetable-tfoot > tr > td {
    padding: .25em .5em;
}

.p-treetable .p-treetable-thead > tr > th .p-column-title {
    vertical-align: middle;
}

.p-treetable .p-sortable-column {
    cursor: pointer;
}

.p-treetable .p-sortable-column-icon {
    vertical-align: middle;
}

.p-treetable-auto-layout > .p-treetable-wrapper {
    overflow-x: auto;
}

.p-treetable-auto-layout > .p-treetable-wrapper > table {
    table-layout: auto;
}

/* Sections */
.p-treetable-header,
.p-treetable-footer {
    padding: .25em .5em;
    text-align: center;
    font-weight: bold;
}

.p-treetable-header {
    border-bottom: 0 none;
}

.p-treetable-footer {
    border-top: 0 none;
}

/* Paginator */
.p-treetable .p-paginator-top {
    border-bottom: 0 none;
}

.p-treetable .p-paginator-bottom {
    border-top: 0 none;
}

/* Scrollable */
.p-treetable-scrollable-wrapper {
    position: relative;
}
.p-treetable-scrollable-header,
.p-treetable-scrollable-footer {
    overflow: hidden;
    border: 0 none;
}

.p-treetable-scrollable-body {
    overflow: auto;
    position: relative;
}

.p-treetable-scrollable-body > table > .p-treetable-tbody > tr:first-child > td {
    border-top: 0 none;
}

.p-treetable-virtual-table {
    position: absolute;
}

/* Frozen Columns */
.p-treetable-frozen-view .p-treetable-scrollable-body {
    overflow: hidden;
}

.p-treetable-frozen-view > .p-treetable-scrollable-body > table > .p-treetable-tbody > tr > td:last-child {
    border-right: 0 none;
}

.p-treetable-unfrozen-view {
    position: absolute;
    top: 0px;
}

/* Filter */
.p-column-filter {
    width: 100%;
}

/* Resizable */
.p-treetable-resizable > .p-treetable-tablewrapper {
    overflow-x: auto;
}

.p-treetable-resizable .p-treetable-thead > tr > th,
.p-treetable-resizable .p-treetable-tfoot > tr > td,
.p-treetable-resizable .p-treetable-tbody > tr > td {
    overflow: hidden;
}

.p-treetable-resizable .p-resizable-column {
    background-clip: padding-box;
    position: relative;
}

.p-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
    display: none;
}

.p-treetable .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: .5em;
    height: 100%;
    padding: 0px;
    cursor:col-resize;
    border: 1px solid transparent;
}

.p-treetable .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
}

/* Selection */
.p-treetable .p-treetable-checkbox {
    margin: 0 .5em 0 .25em;
    vertical-align: middle;
}

/* Edit */
.p-treetable .p-treetable-tbody > tr > td.p-cell-editing .p-component {
    width: 100%;
}

/* Reorder */
.p-treetable-reorder-indicator-up,
.p-treetable-reorder-indicator-down {
    position: absolute;
    display: none;
}

/* Loader */
.p-treetable-loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.p-treetable-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2em;
}
