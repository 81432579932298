.ST-typeFormatter-r{
  height: 5px;
  border-radius: 2.5px;
  background-color: #3699ff;
  width: 5px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.ST-typeFormatter-c{
  height: 5px;
  border-radius: 2.5px;
  background-color: #f64e60;
  width: 5px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.ST-nameInitials{
  background-color: #e1f0ff !important;
  color:  #3699ff !important;
}
.ST-nameFormatter{
  cursor: pointer;
}

.ST-mode-formatter{
  height: 5px;
    border-radius: 2.5px;
    background-color: #657EFF;
    width: 5px;
    display: inline-block;
    margin-right: 5px;
}