.QuickStart{
  height:100%;
}


.QuickStart-header{
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #041E39;
  height: 30%;
}

.QuickStart-heading{
  color:white;
  text-align: center;
}

.QuickStart-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F8F9FF !important;
  height:70%;
}

.QuickStart-list{
  position: relative;
    top: -42px;
}

.QuickStart-cancel{
  cursor: pointer;
  text-align: center;
  color: #3E9CFF;
  font-weight: 500;
}