#users-list{
  padding-top: 94px;

  .table th, .table td{
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .table td{
    vertical-align: middle;
  }
  .table th, .table td{
    color: #696969;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    position: relative;
    text-align: center;
  }
  .table{
    input[type=checkbox]{
      vertical-align: middle;
    }
    .selection-cell,.selection-cell-header{
      text-align: center;
    }
    .type{
      color: #657EFF;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      .oval{
        width: 8px;
        height: 8px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background-color: #657EFF;
        margin-right: 5px;
        display: inline-block;
      }
    }
    svg{
      fill:#999999;
      width:18px;
    }
  }
}


#kt_header.users{
  box-shadow: none;
  .subheader-title{
    color: #696969;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
  }
}
#kt_subheader.users{
  padding-left: 25px;
  padding-right: 25px;
  .subheader-title{
    color: #696969;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    padding-right: 10px;
    border-right: 1px solid #E7E9F5;
  }
  .user-count{
    color: #646C9B;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 10px;
  }

  .search-input-container{
    svg{
      position: absolute;
      right: 5px;
      height: 14px;
      top: 0;
      width: 14px;
      bottom: 0;
      margin: auto;
      path{
        fill: #657EFF;
      opacity: 1;
      }
    }
  }
  #search-user{
    margin-left: 30px;
    border: 0px;
    height: 30px;
    width: 171px;
    border-radius: 4px;
    background-color: #F2F3F7;
    color: #646C9B;
    font-family: Poppins;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 17px;
    padding-left: 13px;
    padding-right: 13px;
  }
  #export-button{
    margin-right: 20px;
    margin-left: 20px;
  
  }
  #export-button, #filter_panel_toggle{
    height: 33px;
  width: 33px;
  border-radius: 4px;
  background-color: #E7E9F5;
  cursor: pointer;
  svg{
    height: 18px;
    width: 18px;
      path,#Rectangle{
        fill:#646C9B;
        opacity: 1;
      }
    }
  }
  
}
#filter_panel{
  padding-top: 0px !important;

    .header-title{
      padding-left: 25px;
      padding-top: 5px;
      padding-bottom: 14px;
      color: #696969;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }
    .offcanvas-content{
      padding-top: 60px;

    }
    .offcanvas-header{
      border-bottom: 3px solid #E7E9F5;
      margin-bottom: 0px !important;
      position: fixed;
    width: 100%;
    padding-top: 16.25px;
    background: #fff;
    z-index: 1000;
    }
    .offcanvas-close{
      z-index: 10000;
    }
    .offcanvas-footer{
      border-top: 1px solid #E7E9F5;
      text-align: center;
    }
    .filter-section{
      padding-top: 15px;
      padding-left: 25px;
      border-bottom: 1px solid #E7E9F5;
     
    }
    .filter-section.no-border{
      border: 0px !important;
    }
    .title{
      color: #646C9B;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }
    ul{list-style: none;
      padding-left: 0px;
      margin: 0px;
      padding-bottom: 20px;
      li{
        margin-top: 19px;
        label{
          margin-left: 14px;
        }
      }
    }
    #close-button, #save-button{
      height: 43px;
      margin-top: 20px;
    }
    #close-button{
      margin-right: 10px;
      width: 89px;
      border: 1px solid #E7E9F5;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #696969;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
    }
    #save-button{
      margin-left: 10px;
      width: 135px;
      font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
    }
}