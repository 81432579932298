.NumberCard{
  height:100%;
}

.NumberCard-top{
  height:50%;
  min-height: 100px;
}

.NumberCard-bottom{
  height:50%;
  border-top: 2px solid #E5E6F0;
  min-height: 100px;
}

.height-100{
  height:100%;
}

.NumberCard-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.border-right-2{
  border-right: 2px solid #E5E6F0;
}