.Mockup-container{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 70px 0;
}

.Mockup{
  height: 500px;
  width: 250px;
  border:5px solid black;
  color: white;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 1px #191919, 0px 0px 0px 2px #111;
  position: relative;
  overflow: hidden;
}

.Mockup:before, .Mockup:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Mockup:before {
  top: 0px;
  width: 56%;
  height: 20px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}

.Mockup .speaker{
  position: absolute;
  display: block;
  color: transparent;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 5px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}

.Mockup .camera{
  position: absolute;
  display: block;
  color: transparent;
  left: -14%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 10px;
  height: 10px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
}

.Mockup .camera:after{
  content: '';
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}

.Mockup-top{
  height:58%;
}

.Mockup-bottom{
  height: 42%;
  padding: 0 10px;
}

.Mockup-tag{
  font-size: 0.8rem;
  background-color: gray;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 20px;
}

.Mockup-buy{
  padding: 10px 40px;
  background-color:white;
  color: black;
  border-radius: 5px;
  margin-right: 5px;
}

.Mockup-details{
  padding:10px 10px;
  background-color: black;
  border-radius: 5px;
}

.Mockup-share{
  padding: 10px 10px;
  font-size: 1.5rem;
  color: white;
}

.Mockup-image{
  width: 100%;
  height: auto;
  max-height: 286.72px;
}

.Mockup-back{
  position: absolute;
  color:white;
  top:15px;
  left:15px;
}

.Mockup-logo{
  position: relative;
    left: -80px;
    top: 30px;
    text-align: right;
}

.Mockup-buttons{
  position: absolute;
    bottom: 25px;
    left: 20px;
}

.Mockup-title{
  overflow-wrap: break-word;
}

.Mockup-description{
  overflow-wrap: break-word;
}