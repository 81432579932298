
.BannerPreview-rec{
  width: 85%;
  height: auto;
}

.BannerPreview-por{
  width: 70%;
  height: auto;
}

.BannerPreview-left{
  display: flex;
  justify-content: center;
  align-items: center;
}

.BannerPreview-right-1{
  padding:20px 10px 0 15px;
  border-bottom: 1px solid #EEF0F8;
}

.BP-right-top{
  padding:20px 10px 0 15px;
  border-bottom: 1px solid #EEF0F8;
}

.BP-right{
  padding:20px 10px 20px 15px;
  border-bottom: 1px solid #EEF0F8;
}

.BP-download{
  width:50%;
  margin:25px auto;
}