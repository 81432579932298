.Banners-top{
  color:white;
  text-align:center;
  padding-top: 35px;
    padding-bottom: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
}

.Banners-bottom{
  margin-top: 20px;
  color: black;
}

.Banners-images{
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 10px;
}
.Banners-images::-webkit-scrollbar{
  display: none;
}

.Banners-image{
  margin-right:15px;
  border-radius: 10px;
  background-color: #8DA5C5;
}