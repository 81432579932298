.headerApp {
  text-align: center;
  font-size: 16px;
  color: #4a4a4a;
  font-family: inherit;
  padding: 15px 20px 20px;
}

.orderStatus {
  height: 80px;
  background-color: #f2f3f7;
  border-top: 1px solid #e3e5ed;
  border-bottom: 1px solid #e3e5ed;
}

#kt_quick_appointment {
  width: 540px;
}
.font12 {
  font-size: 12px;
}

.statusBtn {
  border-radius: 4px !important;
  border: none !important;
  height: 30px !important;
  align-items: center !important;
  display: flex !important;
  background-color: #ffb822 !important;
}

.accptBtn {
  border-radius: 4px !important;
  border: none !important;
  height: 40px !important;
  align-items: center !important;
  display: flex !important;
  width: 190px !important;
  color: white !important;
  background-color: #34a181 !important;
  justify-content: center;
  margin-left: 25px;
}

.rejectBtn {
  border-radius: 4px !important;
  border: none !important;
  height: 40px !important;
  align-items: center !important;
  display: flex !important;
  width: 190px !important;
  color: black !important;
  background-color: #ffffff !important;
  justify-content: center;
}

.serviceHeader {
  padding: 15px;
  font-size: 14px;
  color: #4a4a4a;
  font-family: inherit;
}

.cartH {
  padding-left: 16px;
  font-size: 16px;
  color: #9b9b9b;
  margin-bottom: 10px;
  padding-top: 10px;
}

.cartDiv {
  height: 57px;
  font-family: inherit;
  width: 80px;
}

.cartTime {
  font-size: 14px;
  color: #696969;
  font-weight: 500;
  padding: 10px 10px 5px 10px;
}

.cartDate {
  font-size: 10px;
  color: #9b9b9b;
  padding-left: 10px;
}

.cartDivder {
  background-color: #657eff;
  width: 4px;
  height: 54px;
  border-radius: 4px;
}

.rightPanelFirsts {
  height: 60px;
  padding-left: 28px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.rhtPanelContents {
  display: flex;
  flex-wrap: wrap;
  width: 77%;
  justify-content: space-between;
}

.styleName {
  font-size: 14px;
  color: #696969;
  font-weight: 500;
}

.timing {
  font-size: 12px;
  color: #696969;
}

.person {
  font-size: 12px;
  color: #646c9b;
}
.price {
  color: #696969;
  font-size: 14px;
  float: right;
}

.tax {
  font-size: 10px;
  color: #9b9b9b;
}

.footerApp {
  height: 90px;
  border-top: 1px solid #e3e5ed;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.customer-detail {
  height: 115px;
  border-top: 1px solid #e3e5ed;
  background-color: #f2f3f7;
  padding: 15px 20px 20px 30px;
}

.customerHeading {
  font-size: 14px;
  color: #696969;
  font-family: inherit;
}

.caption {
  width: 50px;
  height: 50px;
  background-color: #5072df;
  color: white;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.pd0 {
  padding-left: 0px;
}

.headingCus {
  font-size: 14px;
  color: #4a4a4a;
  font-family: inherit;
}

.hedingSub {
  padding: 5px 0px;
  font-size: 12px;
  color: #4a4a4a;
  margin-right: 8px;
}

.orderSummryDiv {
  height: 150px;
}

.pd5 {
  padding: 5px 0px 5px 0px;
}
.icon {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.midHeight {
  height: calc(100vh - 510px);
  overflow-y: auto;
  overflow: hidden;
}

.adm-header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

#dropdown-more-options {
  background-color: white;
  color: grey;
  border: 1px solid grey;
}

#dropdown-more-options::after {
  content: none;
}

.adm-footer-btn {
  width: 193px;
  height: 43px;
}

.footerApp .dropdown-menu {
  width: 100%;
}

.footerApp .dropdown-menu a:hover {
  color: #fd397a !important;
}

#orderInfo {
  padding: 20px 20px 20px 35px;
}

#orderInfoStatus {
  padding-top: 15px;
}

@media only screen and (max-width: 990px) {
  .orderStatus {
    height: fit-content;
  }

  .orderStatus > div {
    margin-bottom: 0;
  }

  #orderInfo {
    padding: 0;
    margin: 10px 30px;
  }

  #orderInfoStatus {
    padding-top: 0;
  }

  .rightPanelFirsts {
    padding-left: 0;
    margin-left: 10px;
  }

  .cartDiv {
    width: 25%;
  }

  .cartTime {
    padding-top: 0;
  }

  .rhtPanelContents {
    width: 75%;
  }
}
