.SingleDeal-right{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.SingleDeal-active{
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #E1F0FF;
  color:#3A9AFF;
}


@media (min-width:768px){
  .SingleDeal{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .SingleDeal-left{
    width: 40%;
  }
  
  .SingleDeal-right{
    width:60%;
    justify-content: space-around;
  }

}