.SingleAppointment{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SingleAppointment-left{
  display: flex;
}

.SingleAppointment-time{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SingleAppointment-divider{
  height: 60px;
    width: 4px;
    background-color: #657EFF;
    border-radius: 5px;
    margin: 0 7px;
}

.SingleAppointment-active{
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #E1F0FF;
  color:#3A9AFF;
}

.SingleAppointment-unactive{
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #FFE2E5;
  color:#F75E6F;
}