.TaxesListing{
  height: 100%;
}

.TaxesListing-top-container{
  height: 100%;
}

.TaxesListing-table-container{
  padding: 10px 15px;
}

.TaxesListing-table{
  min-width: 400px;
}

@media(min-width:600px){
  .TaxesListing-table{
    width: 80% !important;
    margin: 0 auto;
  }
  .TaxesListing-width-50{
    width: 50%;
  }
  
  .TaxesListing-width-20{
    width: 20%;
  }
}

.TaxesListing-rows{
  line-height: 3rem;
}

.TaxesListing-rows td{
  border-top: none !important;
}