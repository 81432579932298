.SingleBanner{
  color:black;
}


.SingleBanner-image{
  border-radius: 10px;
  transition: all .2s ease-in-out;
}

.SingleBanner-image:hover{
  transform: scale(1.05);
}

.SingleBanner-rec{
  width:32%;
  margin-bottom:15px;
  cursor:pointer;
  height: auto;
  margin-right: 1.3%;
}

@media (max-width: 768px){
  .SingleBanner-rec{
    width: 49%;
    margin-right: 1%;
  }
}

@media (max-width: 450px){
  .SingleBanner-rec{
    width: 98%;
    margin-right:0;
  }
}

.SingleBanner-por{
  width:19%;
  margin-bottom:15px;
  cursor:pointer;
  height: auto;
  margin-right: 1%;
}

@media (max-width: 992px){
  .SingleBanner-por{
    width: 24%;
  }
}

@media (max-width: 768px){
  .SingleBanner-por{
    width: 32%;
    margin-right: 1.3%;
  }
}

@media (max-width: 530px){
  .SingleBanner-por{
    width: 48%;
  }
}

/* @media (max-width: 400px){
  .SingleBanner-por{
    width: 98%;
  }
} */