.ST-DateFilter-Modal {
  width: 80vw !important;
  max-width: none !important;
}

.ST-DateFilter-dates{
  display: flex;
  justify-content: center;
}

@media (max-width:950px){
  .ST-DateFilter-Modal {
    width: 95vw !important;
  }  
}

@media (max-width:450px){
  .ST-DateFilter-dates{
    flex-direction: column;
    align-items: center;
  }

  .ST-DateFilter-dates .rdrDefinedRangesWrapper{
    border-right: none;
  }
}