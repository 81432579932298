.NewDeal {
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.NewDeal-header{
  padding-top: 10px;
    display: flex;
    justify-content: center;
    color: rgba(0,0,0,.5);
    font-size: 13px;
    border-bottom: 1px solid rgba(145,160,221,.5);
}

.NewDeal-body{
  height: calc(100vh - 110px);
  overflow: auto;
}

.NewDeal-Editor{
  border-right: 2px solid lightgray;
  z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
}

.NewDeal-footer{
  height: 63px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    border-top: 2px solid lightgray;
    position: fixed;
    width: 100%;
    bottom: 5px;
    z-index: 10;
    background-color: white;
}

.NewDeal-cross{
  height: 25px;
    background: #e7e9f5;
    width: 25px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}

