.CategoryCard-collapse{
  border-top: 1px solid #e7e9f5;
}

.CategoryCard-service-name{
  font-size: 1rem;
  color: #696969;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 30px;
  vertical-align: text-bottom;
}

.CategoryCard-service-variation{
  border-top: 1px dashed #e7e9f5;
}

.CategoryCard-service-variation-name{
  padding-left: 50px;
}

.Services-single-service{
  border-top: 1px solid #e7e9f5;
}